export default function Home() {
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-home" />
            관리자 홈 <small>Admin Home</small>
            <div className="pull-right">
              <button className="goBackBtn">공지사항 등록</button>
            </div>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <h4 className="px-2">공지사항</h4>
            <div className="x_content setboard-table-wrap">
              <div className="x_content site-table-wrap">
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered GW-cont-head boardget_table">
                    <thead>
                      <tr>
                        <th className="cont-w50">번호</th>
                        <th className="cont-w150">게시판종류</th>
                        <th>내용</th>
                        <th className="cont-w200">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array(10)
                        .fill(0)
                        .map((code, idx) => {
                          return (
                            <tr className="answertr" key={idx}>
                              <td>{idx + 1}</td>
                              <td>공지사항</td>
                              <td>내용</td>
                              <td>
                                <button className="tableBtn tableBtn-red btnW-70 setPriceDelEvents setpriceBtn">
                                  <span>삭제하기</span>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="boardPagination">
            <div className="pagedWrap">
              <button className="pageArrow off">&lt;&lt;</button>
              <button className="pageArrow off">&lt;</button>
              <button className="pageBtn on">1</button>
              <button className="pageBtn">2</button>
              <button className="pageBtn">3</button>
              <button className="pageArrow">&gt;</button>
              <button className="pageArrow">&gt;&gt;</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
