import { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { useLoading } from '@config/loadingContext';
import { Site, WongoType } from '@interfaces/site.interface';
import useFetchGetone from '@utils/customHook/useFetchGetone';
import { SiteaddIn } from '@dummydata/dummy';
type Phone = {
  areaCode: string;
  prefix: string;
  suffix: string;
};
interface Props {
  editWongoId: string;
  changeModal: (type: string, bool: boolean) => void;
}
export default function WongoSiteModal({ editWongoId, changeModal }: Props) {
  const { loadingHandler } = useLoading();
  const { payload: editPayload } = useFetchGetone<Site>(
    `site/${editWongoId}`,
    editWongoId,
  );
  let wongoDummy = {
    name: '',
    grade: '사원',
    phone: {
      areaCode: '',
      prefix: '',
      suffix: '',
    },
  };
  let gradeOptions = ['사원', '대리', '과장', '차장'];
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const [siteInputs, setsiteInputs] = useState<Site>({
    ...SiteaddIn,
  });
  const [wongoData, setWongoData] = useState<WongoType[]>([{ ...wongoDummy }]);
  useEffect(() => {
    if (editPayload) {
      setsiteInputs(editPayload.data);
      let wongoCopy = editPayload.data.wongo;
      if (wongoCopy) {
        setWongoData([...wongoCopy, { ...wongoDummy }]);
        setSelectedIdx(wongoCopy.length);
      }
    }
  }, [editPayload]);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    idx: number,
  ) => {
    const { name: key, value } = e.target;
    let copy = [...wongoData];
    if (copy[idx]) {
      copy[idx][key] = value;
    }
    setWongoData(copy);
  };
  const handlePhone = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    idx: number,
  ) => {
    const { name, value } = e.target;
    let keyNow = name as keyof Phone;
    let copy = [...wongoData];
    if (copy[idx]) {
      copy[idx].phone[keyNow] = value;
    }
    setWongoData(copy);
  };
  const addWongo = () => {
    let copy = [...wongoData];
    setWongoData([...copy, { ...wongoDummy }]);
    setSelectedIdx(copy.length);
  };
  const delWongo = (idx: number) => {
    let copy = [...wongoData];
    if (copy.length > 1) {
      if (window.confirm('옵션을 삭제하시겠습니까?')) {
        let newData = copy.filter((_, i) => i !== idx);
        setWongoData(newData);
        setSelectedIdx(newData.length - 1);
      }
    }
  };

  const changeActive = (idx: number) => {
    setSelectedIdx(idx);
  };
  const saveNow = async () => {
    let result = [...wongoData];
    let testA = result.findIndex((a) => !a.name);
    let testB = result.findIndex(
      (a) => !a.phone.areaCode || !a.phone.prefix || !a.phone.suffix,
    );
    if (testA !== -1) {
      alert('이름이 비어있습니다');
      setSelectedIdx(testA);
      return;
    }
    if (testB !== -1) {
      alert('직통번호가 비어있습니다');
      setSelectedIdx(testA);
      return;
    }
    loadingHandler(true);
    let siteCopy = {
      topSeller: siteInputs.topSeller,
      sellerName: siteInputs.sellerName,
      sellerNameEN: siteInputs.sellerNameEN,
      siteUrl: siteInputs.siteUrl,
      adminName: siteInputs.adminName,
      adminPosition: siteInputs.adminPosition,
      IdNumber: siteInputs.IdNumber,
      pw: siteInputs.pw,
      wongo: wongoData,
    };
    let siteNow = siteInputs._id;
    await axios
      .put(SERVER_URL + '/site/' + siteNow, siteCopy)
      .then((response) => {
        setsiteInputs(response.data.data);
        loadingHandler(false);
        alert('사이트 수정 성공');
        changeModal('wongo', false);
      })
      .catch(function (error) {
        loadingHandler(false);
        alert('사이트 수정 실패');
      });
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal('wongo', false)}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-800">
        {/* <button onClick={() => goDummy()}>고더미</button> */}
        <div
          className="closeBtn"
          onClick={() => {
            changeModal('wongo', false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {wongoData && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    원고담당자 정보 수정 - <i>{siteInputs.sellerName}</i>
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable rename wongoTable"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>이름</th>
                              <th>직급</th>
                              <th>직통번호</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {wongoData.map((code, idx) => {
                              return (
                                <tr
                                  key={idx}
                                  className={
                                    selectedIdx === idx ? 'active' : ''
                                  }
                                >
                                  <td className="col-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="이름을 입력해주세요"
                                      name="name"
                                      value={code.name}
                                      onChange={(e) => handleChange(e, idx)}
                                      disabled={selectedIdx !== idx}
                                      required
                                    />
                                  </td>
                                  <td className="col-2">
                                    <select
                                      className="form-control site-selcet"
                                      onChange={(e) => handleChange(e, idx)}
                                      name="position"
                                      value={code.grade}
                                      disabled={selectedIdx !== idx}
                                    >
                                      <option value="empty">
                                        직급을 선택하세요
                                      </option>
                                      {gradeOptions.map((option) => {
                                        return (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </td>
                                  <td className="col-7">
                                    <div className="col-3 align-top d-inline-block px-1">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="areaCode"
                                        value={code.phone.areaCode}
                                        onChange={(e) => handlePhone(e, idx)}
                                        disabled={selectedIdx !== idx}
                                        required
                                      />
                                    </div>
                                    <div className="col-3 align-top d-inline-block px-1">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="prefix"
                                        value={code.phone.prefix}
                                        onChange={(e) => handlePhone(e, idx)}
                                        disabled={selectedIdx !== idx}
                                        required
                                      />
                                    </div>
                                    <div className="col-4 align-top d-inline-block px-1">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="suffix"
                                        value={code.phone.suffix}
                                        onChange={(e) => handlePhone(e, idx)}
                                        disabled={selectedIdx !== idx}
                                        required
                                      />
                                    </div>
                                    <div
                                      className={`input-group-btn input-minus-btn${
                                        wongoData.length === 1
                                          ? ' disabled'
                                          : ''
                                      }`}
                                      onClick={() =>
                                        wongoData.length > 1 && delWongo(idx)
                                      }
                                    >
                                      <i className="fa fa-minus"></i>
                                    </div>
                                    <div
                                      className={`input-group-btn input-reorder-btn${
                                        selectedIdx === idx ? ' disabled' : ''
                                      }`}
                                      style={{ marginLeft: '0 !important' }}
                                      onClick={() =>
                                        selectedIdx !== idx && changeActive(idx)
                                      }
                                    >
                                      <i className="fa fa-gear"></i>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="mt-3 mx-auto d-block text-center align-middle">
                          <div
                            className="yBtn yBtn-small orange text-center"
                            onClick={() => addWongo()}
                          >
                            담당자 추가
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  <div
                    className="price-start-btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      saveNow();
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      수정완료
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
