import { Dispatch, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState, useSidbarContext } from '@config/context';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { IAddwork, IId, NewAddwork } from '@interfaces/addwork.interface';
import { Site } from '@interfaces/site.interface';
import Modal from '@components/modal/ModalComp';
import Loglist from '@components/loglist';
import { useLoading } from '@config/loadingContext';
import moment from 'moment';

interface Props {
  selectedSite: Site;
  addworkList: IAddwork[];
  setAddworkList: Dispatch<IAddwork[]>;
  addworkSited: string[];
  setAddworkSited: Dispatch<string[]>;
  addworkUnique: string[];
  changeGFmodalNow: (bool: boolean) => void;
}
interface LogProps {
  log: boolean;
  id: string;
}
export default function GetAddworkModal({
  selectedSite,
  addworkList,
  setAddworkList,
  addworkSited,
  setAddworkSited,
  addworkUnique,
  changeGFmodalNow,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  /**페이지이동 state*/
  const history = useHistory();
  /**로그 모달 */

  const [refreshNow, setRefreshNow] = useState(false);
  const refreshHandler = () => setRefreshNow(!refreshNow);
  const [modal, setModal] = useState<LogProps>({
    log: false,
    id: '',
  });
  const closeModal = (target: string) => {
    refreshHandler();
    setModal({ log: false, id: '' });
  };
  const { setNavOpen } = useSidbarContext();
  /**선택한 메인 state*/
  const [navNow, setNavNow] = useState('전체');
  const [filterAddwork, setFilterAddwork] = useState<IAddwork[]>([
    ...addworkList,
  ]);
  const changeNav = (name: string) => {
    if (name === '전체') {
      setFilterAddwork([...addworkList]);
    } else {
      let FN = addworkList.filter((t) => t.groupCode === name);
      setFilterAddwork(FN);
    }
    setNavNow(name);
  };
  const checkGWItem = (ID: string) => {
    let copyFilter = [...filterAddwork];
    let IndexN = copyFilter.findIndex((a) => a._id === ID);
    copyFilter[IndexN].checkbox = !copyFilter[IndexN].checkbox;
    setFilterAddwork(copyFilter);
  };
  const passProps = selectedSite.sellerNameEN;
  const postNewItems = async (arr: NewAddwork[]) => {
    await axios
      .all(
        arr.map((nData) =>
          axios
            .post(SERVER_URL + '/addwork', nData)
            .then((res) => {
              res && setAddworkSited([...addworkSited, res.data.data.originId]);
            })
            .catch((err) => {
              console.log('err', err);
              return false;
            }),
        ),
      )
      .then((data) => {
        console.log('가져가기 완료');
        loadingHandler(false);
        history.push({
          pathname: '/product-manager/setaddwork',
          state: { passProps: passProps },
        });
        return true;
      })
      .catch((err) => {
        console.log('err', err);
        return false;
      });
  };
  const sendSelectProducts = async () => {
    loadingHandler(true);
    let params = filterAddwork
      .filter((a) => a.checkbox === true)
      .map((b) => {
        return SERVER_URL + '/addwork/' + b._id;
      });
    let oldData: NewAddwork[] = [];
    params &&
      (await axios
        .all(
          params.map((url) =>
            axios
              .get(url)
              .then((res) => {
                oldData.push(res.data.data);
              })
              .catch((err) => {
                console.log(err);
                loadingHandler(false);
              }),
          ),
        )
        .then(async (res) => {
          console.log('데이터 받기 완료');
          let newData = oldData.map((copy) => {
            return {
              productId: copy.productId,
              site: passProps,
              originId: copy._id,
              groupCode: copy.groupCode,
              workType: copy.workType,
              workTypeKO: copy.workTypeKO,
              selecters: copy.selecters.map((a) => {
                delete a._id;
                return {
                  ...a,
                  select: a.select.map((b) => {
                    delete b._id;
                    return b;
                  }),
                };
              }),
              price: copy.price.map((a) => {
                return {
                  code: a.code,
                  value: a.value.map((b) => {
                    delete b._id;
                    return b;
                  }),
                };
              }),
              class: copy.class,
            };
          });
          newData && (await postNewItems(newData));
        })
        .catch((err) => {
          console.log('err', err);
          // loadingHandler(false);
          // changeGFmodalNow(false);
          // setNavOpen('setprice');
          // history.push({
          //   pathname: '/product-manager/setprice',
          //   state: { passProps: passProps },
          // });
        }));
  };
  const sendSelectOne = async (ID: string) => {
    loadingHandler(true);
    await axios
      .get(SERVER_URL + '/addwork/' + ID)
      .then(async (res) => {
        console.log('데이터 받기 완료');
        let copy: NewAddwork = res.data.data;
        let newData: NewAddwork = {
          productId: copy.productId,
          site: passProps,
          originId: copy._id,
          groupCode: copy.groupCode,
          workType: copy.workType,
          workTypeKO: copy.workTypeKO,
          selecters: copy.selecters.map((a) => {
            delete a._id;
            return {
              ...a,
              select: a.select.map((b) => {
                delete b._id;
                return b;
              }),
            };
          }),
          price: copy.price.map((a) => {
            return {
              code: a.code,
              value: a.value.map((b) => {
                delete b._id;
                return b;
              }),
            };
          }),
          class: copy.class,
        };
        newData &&
          (await axios
            .post(SERVER_URL + '/addwork', newData)
            .then((res) => {
              res && setAddworkSited([...addworkSited, res.data.data.originId]);
              console.log('데이터 전송 완료');
              loadingHandler(false);
            })
            .catch((err) => {
              console.log(err);
              loadingHandler(false);
            }));
      })
      .catch((err) => {
        console.log(err);
        loadingHandler(false);
      });
  };
  return (
    <>
      <div className="getProduct-window ">
        <div className="GW-bg"></div>
        <div className="GW-wrap">
          <div className="closeBtn" onClick={() => changeGFmodalNow(false)}>
            <i className="fa fa-times"></i>
          </div>
          <div className="GW-title mb-3">
            <h4>기준 후가공 가져오기</h4>
          </div>
          <div className="GW-inner">
            <div className="GW-inner-left">
              <ul>
                <li
                  className={`GW-nav ${
                    navNow && navNow === '전체' ? 'active' : ''
                  }`}
                  onClick={() => changeNav('전체')}
                >
                  전체
                </li>
                {addworkUnique &&
                  addworkUnique.map((list) => {
                    return (
                      <li
                        className={`GW-nav ${
                          navNow && navNow === list ? 'active' : ''
                        }`}
                        key={list}
                        onClick={() => changeNav(list)}
                      >
                        {list}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="GW-inner-right">
              <div className="GW-cont">
                <div className="GW-cont-head">
                  <ul>
                    <li>
                      <div className="GW-cont-itemIndex cont-w95">번호</div>
                      <div className="cont-w150">그룹명</div>
                      <div className="cont-w150">제품명</div>
                      <div className="cont-w95"></div>
                      <div className="cont-wEX460"></div>
                      <div className="cont-w95">선택</div>
                    </li>
                  </ul>
                </div>
                <div className="GW-cont-body">
                  <ul>
                    {filterAddwork &&
                      filterAddwork.map((cont, index) => {
                        return (
                          <li
                            className={`GW-cont-item ${
                              addworkSited.includes(cont._id) ? 'sited' : ''
                            }`}
                            key={cont._id}
                          >
                            <div className="GW-cont-itemIndex cont-w95 ">
                              {!addworkSited.includes(cont._id) ? (
                                <label htmlFor={'select' + index + 1}>
                                  <input
                                    id={'select' + index + 1}
                                    type="checkbox"
                                    onChange={() => checkGWItem(cont._id)}
                                    checked={
                                      filterAddwork[index].checkbox === true
                                    }
                                  />
                                  <span>{index + 1}</span>
                                </label>
                              ) : (
                                <label
                                  className="nodrop"
                                  htmlFor={'select' + index + 1}
                                >
                                  <input
                                    id={'select' + index + 1}
                                    type="checkbox"
                                    checked={true}
                                    readOnly
                                  />
                                  <span>{index + 1}</span>
                                </label>
                              )}
                            </div>
                            <div className="GW-cont-itemCate01 cont-w150">
                              {cont.groupCode}
                            </div>
                            <div className="GW-cont-itemProduct cont-w150">
                              {cont.workTypeKO}
                            </div>
                            <div className="GW-cont-itemCate01 cont-w95"></div>
                            <div className="GW-cont-itembtns cont-wEX460"></div>

                            <div className="GW-cont-selectwrap cont-w95">
                              {!addworkSited.includes(cont._id) ? (
                                <div
                                  className="btn yBtn yBtn-lg purple btn-inline-block false"
                                  data-toggle="tooltip"
                                  onClick={() => sendSelectOne(cont._id)}
                                >
                                  <i className="fa fa-check"></i> 선택
                                </div>
                              ) : (
                                <div
                                  className="btn yBtn yBtn-lg gray btn-inline-block false nodrop"
                                  data-toggle="tooltip"
                                >
                                  <i className="fa fa-check"></i> 선택
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="GW-btns mt-4">
            <div
              className="btn yBtn yBtn-xl purple btn-inline-block false"
              data-toggle="tooltip"
              onClick={() => sendSelectProducts()}
            >
              <i className="fa fa-check"></i> 기준상품 선택
            </div>
          </div>
        </div>

        {modal.log && (
          <Modal close={closeModal} title={'로그 리스트'} id={'log'}>
            <Loglist id={modal.id} category="addwork" />
          </Modal>
        )}
      </div>
    </>
  );
}
