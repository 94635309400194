import { useState, useEffect } from 'react';
import moment from 'moment';
import { IHomeData } from '@interfaces/home.interface';
import { ICoupon, ICouponData } from '@interfaces/tableCost.interfaces';
import useFetchNoData from '@utils/customHook/useFetchNoData';
interface IProps {
  dataNow: IHomeData;
}
export default function homeComponent({ dataNow }: IProps) {
  // 쿠폰 데이터

  const { payload, error } = useFetchNoData<ICoupon>(
    `event-coupon?isUsed=true&isPaid=true&paidAt=${moment().format(
      'yyyy-MM-DD',
    )}`,
  );

  const [couponNow, setCouponNow] = useState<ICouponData[]>();
  useEffect(() => {
    if (payload) {
      setCouponNow(payload.data);
    }
  }, [payload]);

  return (
    <>
      {couponNow && couponNow.length > 0 ? (
        <div className="userInfo-dash">
          {dataNow.users && (
            <table>
              <tbody>
                {couponNow.map((code, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className="userId couponuserId">
                          <h5>id: </h5> <span>{code.userId}</span>
                        </div>
                        <div className="userName">
                          <h5>상품명: </h5>
                          <span>{code.productName}</span>
                        </div>
                        <div className="userSales couponCode">
                          <h5>쿠폰번호: </h5>
                          <span>
                            {code.couponCode.slice(0, 4) +
                              '-' +
                              code.couponCode.slice(4, 8)}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <>
          <div>오늘 쿠폰주문 없음</div>
        </>
      )}
    </>
  );
}
