import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import { useHistory } from 'react-router-dom';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput, Notification } from '@interfaces/tableCost.interfaces';
import S3ImageLoader from '@components/s3ImageLoader';
import { S3 } from 'aws-sdk';
import { useLoading } from '@config/loadingContext';
import 'react-datepicker/dist/react-datepicker.css';
import { Site } from '@interfaces/site.interface';
import Texteditor from './texteditor';
import useFetchGetone from '@utils/customHook/useFetchGetone';
import { notifiCationDummy } from '@dummydata/dummy';

interface Props {
  editId?: string;
  closeModal: () => void;
}

export default function notificationModal({ editId, closeModal }: Props) {
  const { loadingNow, loadingHandler } = useLoading();

  const { payload: editPayload } = useFetchGetone<Notification>(
    `notification/${editId}`,
    editId,
  );

  // 공지사항 인풋
  const [notificationInputs, setNotificationInputs] = useState<Notification>({
    ...notifiCationDummy,
  });

  const isNew: boolean =
    !notificationInputs._id && notificationInputs._id !== '';

  useEffect(() => {
    if (editPayload && editId) {
      setNotificationInputs(editPayload.data);
    }
  }, [editPayload]);

  const closeNow = () => {
    setNotificationInputs({ ...notifiCationDummy });
    closeModal();
  };

  const getImgUrl = (element: { url: string; key: string }) => {
    handleimg(element.url);
  };

  //텍스트 에디터

  const noticeChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setNotificationInputs({ ...notificationInputs, [name]: value });
  };

  //사이트 리스트 불러오기

  const { payload, error } = useFetch<Site>(`site`, loadingNow);

  //공지사항 타입 값
  const notificationTypes = [
    '회계공지',
    '휴무공지',
    '신제품공지',
    '시스템공지',
    '긴급공지',
    '일반공지',
    '이벤트공지',
  ];
  const handleKind = (type: string) => {
    setNotificationInputs({ ...notificationInputs, notificationType: type });
  };

  //공지사항 인풋 값

  const handleSite = (name: string) => {
    let sitecopy = [...notificationInputs.appliedSites];
    let result = sitecopy.includes(name)
      ? sitecopy.filter((data) => data !== name)
      : [...sitecopy, name];
    setNotificationInputs({ ...notificationInputs, appliedSites: result });
  };

  const [allSite, setAllSite] = useState<boolean>(false);

  const asite = payload?.data.map((list) => list.sellerNameEN);

  const handleSiteAll = () => {
    if (!allSite) {
      asite &&
        setNotificationInputs({ ...notificationInputs, appliedSites: asite });
      setAllSite(true);
    } else {
      setNotificationInputs({ ...notificationInputs, appliedSites: [] });
      setAllSite(false);
    }
  };

  const handlenotification = (name: string, value: string | boolean) => {
    setNotificationInputs({ ...notificationInputs, [name]: value });
  };

  const handleimg = (popUrl: string) => {
    setNotificationInputs({ ...notificationInputs, imgUrl: popUrl });
  };

  const saveNow = async () => {
    loadingHandler(true);
    let notificationCopy = { ...notificationInputs };
    try {
      const response = isNew
        ? await axios.post(SERVER_URL + '/notification', notificationCopy)
        : await axios.put(
            SERVER_URL + '/notification/' + notificationCopy._id,
            notificationCopy,
          );
      if (response) {
        setNotificationInputs(response.data.data);
        isNew ? alert('등록 성공') : alert('수정 성공');
        loadingHandler(false);
        closeNow();
      }
    } catch (e) {
      loadingHandler(false);
      alert('등록실패');
      console.log(e);
    }
    loadingHandler(false);
  };

  return (
    <>
      <div className="modalY-overlay" onClick={() => closeNow()}></div>
      <div id="standardproduct-window" className="preview-window ">
        <div
          className="closeBtn"
          onClick={() => {
            closeNow();
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {notificationInputs && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>공지사항 등록</h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>옵션명</th>
                              <th>등록할 공지사항 옵션 선택</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">적용 사이트</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <div className="popupall_btn d-inline-block">
                                      <input
                                        id="all"
                                        className="d-inline-block align-middle"
                                        name="all-input"
                                        type="checkbox"
                                        checked={
                                          notificationInputs.appliedSites
                                            ?.length === asite?.length
                                            ? true
                                            : false
                                        }
                                        value={notificationInputs.appliedSites}
                                        onChange={() => handleSiteAll()}
                                      />
                                      <label
                                        htmlFor="all"
                                        className="d-inline-block align-middle"
                                      >
                                        전체 선택
                                      </label>
                                    </div>
                                    {payload ? (
                                      payload.data.map((list) => (
                                        <React.Fragment key={list._id}>
                                          <input
                                            id={`${list.sellerNameEN}-input`}
                                            className="todayInputs"
                                            name={`${list.sellerNameEN}-input`}
                                            type="checkbox"
                                            checked={notificationInputs?.appliedSites?.includes(
                                              list.sellerNameEN,
                                            )}
                                            onChange={() =>
                                              handleSite(list.sellerNameEN)
                                            }
                                          />
                                          <label
                                            htmlFor={`${list.sellerNameEN}-input`}
                                          >
                                            {list.sellerName}
                                          </label>
                                        </React.Fragment>
                                      ))
                                    ) : (
                                      <p>등록된 사이트가 없습니다.</p>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">타입</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    {notificationTypes &&
                                      notificationTypes.map((code) => {
                                        return (
                                          <React.Fragment key={code}>
                                            <input
                                              id={`${code}-input`}
                                              className="todayInputs"
                                              name={`${code}-input`}
                                              type="checkbox"
                                              checked={
                                                notificationInputs.notificationType ===
                                                code
                                              }
                                              onChange={() => handleKind(code)}
                                            />
                                            <label htmlFor={`${code}-input`}>
                                              {code}
                                            </label>
                                          </React.Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">제목</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <input
                                      className="nocInput popuptitle-input"
                                      type="text"
                                      value={notificationInputs.title}
                                      onChange={(e) =>
                                        handlenotification(
                                          e.target.name,
                                          e.target.value,
                                        )
                                      }
                                      name="title"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selecterName">
                                  공지사항 이미지
                                </div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <div className="noticeUp nbottom">
                                      <S3ImageLoader
                                        subCategories={'notification'}
                                        getImgUrl={getImgUrl}
                                      />
                                      {/* <p>
                                        ※ 이미지의 용량은 00M이하로 등록해주시고
                                        가로최대 000px/세로최대 000px 이하로
                                        등록해주세요.
                                      </p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="popupimg-pv mt-2">
                                  <div className="noticeimg scroolBar">
                                    {notificationInputs.imgUrl == '' ? (
                                      <p>공지사항 이미지 미리보기</p>
                                    ) : (
                                      <img
                                        src={notificationInputs.imgUrl}
                                        alt={notificationInputs.imgUrl}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selecterName">
                                  공지사항 텍스트
                                </div>
                              </td>
                              <td className="todayInputs-content editor-td notification-editor-wrap">
                                <Texteditor
                                  noticeChange={noticeChange}
                                  descriptiondata={
                                    notificationInputs.description
                                  }
                                  newedit={isNew ? 'new' : 'modal'}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">링크 URL</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group input-group-no">
                                    <input
                                      className="nocInput popuptitle-input"
                                      type="text"
                                      value={notificationInputs.linkUrl}
                                      onChange={(e) =>
                                        handlenotification(
                                          e.target.name,
                                          e.target.value,
                                        )
                                      }
                                      name="linkUrl"
                                    />
                                    <p>
                                      ※ 버튼을 클릭할때 연결하고 싶은 페이지의
                                      url을 드레그하여 복사/붙여넣기해주세요.
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row py-1">
                <div className="col-sm-12  text-center complete-btn-wrap my-3">
                  <div className=" mt-3 mb-3 mx-auto d-block text-center align-middle complete-btn-wrap">
                    <div
                      className="btn btn-success yBtn green yBtn-small d-inline-block"
                      onClick={() => {
                        saveNow();
                      }}
                    >
                      {isNew ? (
                        <>
                          <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                          등록완료
                        </>
                      ) : (
                        <>
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          수정완료
                        </>
                      )}
                    </div>
                    <div
                      className="btn yBtn yBtn-small btn btn-inline-block"
                      data-toggle="tooltip"
                      onClick={() => {
                        closeNow();
                      }}
                    >
                      <span>
                        <i className="fa fa-check" aria-hidden="true"></i> 닫기
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
