import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@components/modal/ModalComp';
import UserModal from '@components/modal/userModal';
import { SERVER_URL } from '@config/path';
import moment from 'moment';
import { UserDummy } from '@dummydata/dummy';
import axios, { AxiosRequestConfig } from 'axios';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import { Tuser } from '@interfaces/globals.types';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { deleteEverything } from '@utils/common';
import { Site } from '@interfaces/site.interface';
import useFetch from '@utils/customHook/useFetch';
export default function CreateUser() {
  moment.locale();
  const [modal, setModal] = useState<{ [key: string]: boolean }>({
    userNew: false,
    userEdit: false,
  });
  const [userId, setUserId] = useState<string>();
  const [reload, setReload] = useState<boolean>(false);
  const openModal = useCallback(
    (type: 'userNew' | 'userEdit', id?: string) => {
      setModal({ ...modal, [type]: !modal[type] });
      setUserId(id);
    },
    [modal],
  );

  const { payload, error } = useFetchNoData<Tuser[]>(`user`, reload);
  const [userList, setUserList] = useState<Tuser[]>([]);
  useEffect(() => {
    if (payload) {
      nsortBy(nsortNow.type, nsortNow.key, [...payload]);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);

  const closeModal = () => {
    setModal({ userNew: false, userEdit: false });
    setReload(!reload);
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: true,
    key: 'userClass',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (type: boolean, key: string, data: Tuser[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    // Define custom order for userClass
    const userClassOrder: Record<string, number> = {
      firstDegreeManager: 1,
      'general manager': 2,
      'common manager': 3,
      manager: 4,
    };

    copy.sort((a, b) => {
      const aValue = String(a[key]);
      const bValue = String(b[key]);

      if (key === 'userClass') {
        const orderA = userClassOrder[aValue] || Infinity;
        const orderB = userClassOrder[bValue] || Infinity;
        return type ? orderA - orderB : orderB - orderA;
      }
      if (!type) {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) < String(b[key]) ? -1 : 1;
      } else {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) > String(b[key]) ? -1 : 1;
      }
    });
    setNsortNow({ type: type, key: key });
    setUserList(copy);
  };

  /** S: 후가공 API */
  const UseUserAPI = async (kind: 'new' | 'del' | 'edit', item: Tuser) => {
    let userIDCopy = item._id;
    let finalCopy: Tuser = deleteEverything<Tuser>(item, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      '__v',
    ]);
    const requestOptions: AxiosRequestConfig =
      kind === 'new'
        ? {
            url: SERVER_URL + '/user',
            data: finalCopy,
            method: 'POST',
          }
        : kind === 'del'
        ? {
            url: SERVER_URL + '/user/' + userIDCopy,
            method: 'DELETE',
          }
        : {
            url: SERVER_URL + '/user/' + userIDCopy,
            data: finalCopy,
            method: 'PUT',
          };
    try {
      await axios(requestOptions);
      if (kind === 'del') {
        alert('삭제완료');
      } else {
        alert('저장완료');
        closeModal();
      }
      setReload(!reload);
    } catch (e) {
      alert(`${kind !== 'del' ? '저장' : '삭제'} 실패`);
      setReload(!reload);
      console.log(e);
    }
  };
  /** E: 후가공 API */
  const [siteList, setsiteList] = useState<Site[]>([]);
  const { payload: sitePayload } = useFetch<Site>(`site`, reload);
  useEffect(() => {
    if (sitePayload) {
      let copy: Site[] = sitePayload.data;
      setsiteList(copy);
    } else {
      console.log('payload yet!');
    }
  }, [sitePayload]);
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-user" />
            계정관리 <small>계정 정보 등록</small>
            <div className="pull-right">
              <button
                className="topButtons purple"
                onClick={() => openModal('userNew')}
              >
                신규 관리자 등록
              </button>
            </div>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel setETC-table-wrap table-wrap-green">
            <div className="x_content priceList-table-wrap">
              <div className="react-bootstrap-table">
                <table
                  id="addwork-table"
                  className="table table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th
                        className={`sortable ${
                          sortColor === 'userId' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'userId', userList);
                        }}
                      >
                        아이디
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'userName' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'userName', userList);
                        }}
                      >
                        이름
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'userClass' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'userClass', userList);
                        }}
                      >
                        유저등급
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th>직종</th>
                      <th>담당사이트</th>
                      <th
                        className={`sortable ${
                          sortColor === 'createdAt' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'createdAt', userList);
                        }}
                      >
                        생성일
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'modifiedAt' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'modifiedAt', userList);
                        }}
                      >
                        수정일
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th className="">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList &&
                      userList.map((code, idx) => {
                        let nameMatch = siteList.filter(
                          (a) => a.sellerNameEN === code.siteFilter,
                        );
                        let nameFinded = nameMatch[0]
                          ? nameMatch[0].sellerName
                          : '-';
                        return (
                          <tr key={idx}>
                            <td>{code.userId}</td>
                            <td>{code.userName}</td>
                            <td>
                              {code.userClass === 'firstDegreeManager'
                                ? '최고 관리자'
                                : code.userClass === 'general manager'
                                ? '사이트 관리자'
                                : code.userClass === 'common manager'
                                ? '일반 관리자'
                                : '직원'}
                            </td>
                            <td>
                              {code.occupation
                                ? code.occupation === 'wongoManager'
                                  ? '원고 관리자'
                                  : code.userClass === 'wongoManager'
                                  ? '파일 관리자'
                                  : '-'
                                : '-'}
                            </td>
                            <td>{nameFinded}</td>
                            <td>
                              {' '}
                              {moment(code.createdAt).format(
                                'YYYY-MM-DD (HH:mm)',
                              )}
                            </td>
                            <td>
                              {' '}
                              {moment(code.modifiedAt).format(
                                'YYYY-MM-DD (HH:mm)',
                              )}
                            </td>

                            <td>
                              <button
                                className="tableBtn tableBtn-orange btnW-70"
                                id="modify"
                                onClick={() =>
                                  code._id && openModal('userEdit', code.userId)
                                }
                              >
                                수정
                              </button>
                              <button
                                className="tableBtn tableBtn-red btnW-70"
                                id="del"
                                onClick={() =>
                                  code._id && UseUserAPI('del', code)
                                }
                              >
                                삭제
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {modal.userNew && (
              <>
                <UserModal
                  userType="userNew"
                  siteList={siteList}
                  UseUserAPI={UseUserAPI}
                  closeModal={closeModal}
                />
              </>
            )}
            {modal.userEdit && userId && (
              <>
                <UserModal
                  userType="userEdit"
                  siteList={siteList}
                  userId={userId}
                  UseUserAPI={UseUserAPI}
                  closeModal={closeModal}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
