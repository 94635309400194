import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import SPPriceTable from '@components/table/productmanager/price-manager-table';
import alertCategorize from '@utils/alertFunc';
import {
  FirstInput,
  EditInput,
  TableCostStep,
  Order,
  TotalCalc,
} from '@interfaces/tableCost.interfaces';
import { useAuthState } from '@config/context';
import { emptyPrice } from '@dummydata/dummy';
// import { compare } from '@config/compare/compareBefore';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { getLatest } from '@utils/common';
import NumberFormat from 'react-number-format';
import { stringNumberFunc } from '@utils/common';
import { useLoading } from '@config/loadingContext';
import { YUNeditdummy } from '@dummydata/yundummy';
interface Props {
  siteName: string;
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  changeModal: (type: string, bool: boolean) => void;
}

export default function SPPriceModal({
  siteName,
  firstInputs,
  setFirstInputs,
  changeModal,
}: Props) {
  const { loadingHandler } = useLoading();
  /**ref*/
  const myRef = useRef<HTMLDivElement>(null);
  /**제품분리(도수외) state*/
  const [latestInput, setLatestInput] = useState<Order[]>([]);
  /**제품분리(도수) state*/
  const [latestColor, setLatestColor] = useState<Order[]>([]);
  /**가격표없는옵션분리state*/
  const [noPriceInput, setNoPriceInput] = useState<Order[]>([]);

  const [totalCalc, setTotalCalc] = useState<TotalCalc>({
    unit: '',
    amount: 0,
  });
  useEffect(() => {
    let totalCalcVal = firstInputs.totalPriceCalc
      ?.replace('total', '')
      .replace('(', '')
      .replace(')', '')
      .replace('%', '');
    firstInputs.totalPriceCalc?.startsWith('total') &&
      setTotalCalc({
        unit: firstInputs.totalPriceCalc?.includes('%') ? '%' : '원',
        amount: Number(totalCalcVal),
      });
  }, []);
  const [editInputs, setEditInputs] = useState<EditInput>({
    _id: firstInputs._id,
    totalPriceCalc: firstInputs.totalPriceCalc,
    productTypes: firstInputs.productTypes,
  });

  const roundDown = [10, 100];
  const [roundDownNow, setRoundDownNow] = useState<number>(
    firstInputs.roundDown ? firstInputs.roundDown : 0,
  );
  const changeRoundDown = (int: number) => {
    setRoundDownNow(int);
  };
  const [totalPatial, setTotalPatial] = useState<boolean>(true);
  const handlTotalPatial = () => {
    if (totalPatial) {
      if (window.confirm(CONFIRM.PATIALZERO)) {
        setEditInputs({
          ...editInputs,
          productTypes: editInputs.productTypes.map((codeA) => {
            return {
              ...codeA,
              price: codeA.price.map((codeB) => {
                return { ...codeB, calcValue: 0 };
              }),
            };
          }),
        });
        setTotalPatial(!totalPatial);
      }
    } else {
      setTotalPatial(!totalPatial);
    }
  };
  /**가격 스텝 State*/
  const [priceStep, setPriceStep] = useState<TableCostStep>('holdOrder');
  /**
   * 입력 단계변경 action
   * @param step 변경할 스텝
   * @constant priceStep
   */
  const changePriceStep = async (step: TableCostStep) => {
    setPriceStep(step);
  };
  /**
   * priceStep 변함의 따라 생기는 분기가 복잡, alertCategorize 라는 함수에 각 인풋별 경고창 분기
   * @param step 현재의 priceStep
   * @param inputs fistinput 같은 post 시 파라미터
   */
  useEffect(() => {
    if (priceStep === 'holdOrder') {
      getLatest(firstInputs, setLatestInput, setLatestColor, setNoPriceInput),
        changePriceStep('readyToMake'),
        loadingHandler(false);
    }
  }, [priceStep]);
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const saveNow = async (action: string) => {
    loadingHandler(true);
    let address = firstInputs.productNameEN;
    let productBySiteId = firstInputs._id;
    let siteNow = firstInputs.site;

    let editINputCopy = { ...editInputs };

    let calcNow = editINputCopy.totalPriceCalc;
    let partialTypes = editINputCopy.productTypes
      .filter((a) => a.price.some((b) => b.calcValue != 0))
      .map((c) => {
        return {
          _id: c._id,
          price: c.price.filter((b) => b.calcValue != 0),
        };
      });

    let totalTypes = editINputCopy.productTypes.map((codeA) => {
      return {
        selectors: codeA.selectors.map(({ _id, ...rest }) => {
          return rest;
        }),
        price: codeA.price.map(({ _id, ...rest }) => {
          return rest;
        }),
      };
    });
    if (calcNow === 'total') {
      alert('전체가를 입력하세요');
      return;
    } else {
      if (calcNow === 'partial') {
        await axios
          .put(SERVER_URL + `/productbysite/${productBySiteId}/updatepart`, {
            data: partialTypes,
          })
          .then(function (response) {
            changeModal('price', false);
            loadingHandler(false);
            alert(MESSAGE.SAVE);
          })
          .catch(function (error) {
            console.log('데이터 업로드에 실패 하였습니다.');
            loadingHandler(false);
          });
      } else if (calcNow?.startsWith('total') || calcNow === 'standard') {
        // let Query =
        //   '/productbysite/updateall?target=' +
        //   address +
        //   '&site=' +
        //   siteNow +
        //   '&totalPriceCalc=' +
        // calcNow +
        // '&roundDown=' +
        // roundDownNow;
        await axios
          .put(SERVER_URL + `/productbysite/${firstInputs._id}`, {
            productTypes: totalTypes,
            roundDown: roundDownNow,
            totalPriceCalc: calcNow,
          })
          .then(function (response) {
            changeModal('price', false);
            loadingHandler(false);
            alert(MESSAGE.SAVE);
          })
          .catch(function (error) {
            console.log('데이터 업로드에 실패 하였습니다.');
            loadingHandler(false);
          });
      } else if (calcNow === 'needSetting') {
        alert('가격 적용방식을 선택해주세요.');
        loadingHandler(false);
      }
    }
  };
  const PriceCalcChange = (type: string) => {
    setEditInputs({ ...editInputs, totalPriceCalc: type });
    if (type !== 'total') {
      setTotalCalc({
        unit: '',
        amount: 0,
      });
    } else {
      setTotalPatial(true);
      setTotalCalc({
        unit: '%',
        amount: 0,
      });
    }
    setRoundDownNow(firstInputs.roundDown ? firstInputs.roundDown : 0);
  };
  const TotalCalcChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    let { name, value } = e.target;
    setTotalCalc({ ...totalCalc, [name]: value });
    if (name == 'unit' && value == '%') {
      setEditInputs({
        ...editInputs,
        totalPriceCalc: 'total(' + totalCalc.amount + value + ')',
      });
    } else if (name == 'unit' && value == '원') {
      setEditInputs({
        ...editInputs,
        totalPriceCalc: 'total(' + totalCalc.amount + ')',
      });
    } else if (name == 'amount') {
      let valPre =
        value !== '0' && value !== '' ? stringNumberFunc(e.target.value) : 0;
      let valNow = totalCalc.unit === '%' ? valPre + '%' : valPre;
      setEditInputs({
        ...editInputs,
        totalPriceCalc: 'total(' + valNow + ')',
      });
    } else {
      setEditInputs({
        ...editInputs,
        totalPriceCalc: 'total',
      });
      setTotalCalc({
        unit: '',
        amount: 0,
      });
      alert('전체가 적용을 하시려면, 단위를 선택 하셔야 합니다.');
    }
  };

  // const goDummy = () => {
  //   setEditInputs({ ...YUNeditdummy });
  // };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal('price', false)}
      ></div>
      <div id="standardproduct-window" className="previewY preview-window">
        {/* <button onClick={() => goDummy()}>고더미</button> */}
        <div
          className="closeBtn"
          onClick={() => {
            changeModal('price', false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{siteName}</i>
                    </span>
                    - {firstInputs.productNameKO} 가격적용
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="col-12">
                  <form className="form-horizontal form-label-left">
                    <div className="form-group">
                      <div className="row">
                        <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                          가격 적용방식
                        </label>
                        <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                          <div className="siteprice-radio">
                            <input
                              id="needSetting"
                              value="needSetting"
                              name="siteprice"
                              type="radio"
                              checked={
                                editInputs.totalPriceCalc === 'needSetting'
                              }
                              onChange={() => PriceCalcChange('needSetting')}
                            />
                            <label htmlFor="needSetting">설정전</label>
                          </div>
                          <div className="siteprice-radio">
                            <input
                              id="standard"
                              value="standard"
                              name="siteprice"
                              type="radio"
                              checked={editInputs.totalPriceCalc === 'standard'}
                              onChange={() => PriceCalcChange('standard')}
                            />
                            <label htmlFor="standard">기본가격 적용</label>
                          </div>
                          <div className="siteprice-radio">
                            <input
                              id="total"
                              value="total"
                              name="siteprice"
                              type="radio"
                              checked={editInputs.totalPriceCalc?.startsWith(
                                'total',
                              )}
                              onChange={() => PriceCalcChange('total')}
                            />
                            <label htmlFor="total">전체가 변경</label>
                          </div>
                          <div className="siteprice-radio">
                            <input
                              id="partial"
                              value="partial"
                              name="siteprice"
                              type="radio"
                              checked={editInputs.totalPriceCalc === 'partial'}
                              onChange={() => PriceCalcChange('partial')}
                            />
                            <label htmlFor="partial">일부가 변경</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {editInputs.totalPriceCalc?.startsWith('total') && (
                      <div className="form-group">
                        <div className="row">
                          <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                            전체가 적용
                          </label>
                          <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                            <div className="sitepriceAll-wrap">
                              <div className="siteprice-select d-inline-block">
                                <select
                                  className="text-center"
                                  value={totalCalc.unit}
                                  name="unit"
                                  onChange={(e) => TotalCalcChange(e)}
                                >
                                  <option value="%">퍼센트(%)</option>
                                  <option value="원">금액(±)</option>
                                </select>
                              </div>
                              <div className="siteprice-select d-inline-block">
                                <NumberFormat
                                  name="amount"
                                  type="text"
                                  value={totalCalc.amount}
                                  onChange={(e) => TotalCalcChange(e)}
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </div>
                              <div className="siteprice-select-total d-inline-block">
                                {totalCalc.amount &&
                                  totalCalc.amount !== 0 &&
                                  totalCalc.unit && (
                                    <>
                                      {totalCalc.amount >= 0 ? '+' : ''}
                                      {totalCalc.amount.toLocaleString()}
                                      {totalCalc.unit}
                                    </>
                                  )}
                              </div>

                              <div className="siteprice-all-patial d-inline-block">
                                <input
                                  id="additional-patial"
                                  name="additional-patial"
                                  type="checkbox"
                                  className="d-inline-block align-middle"
                                  checked={totalPatial}
                                  onChange={() => handlTotalPatial()}
                                />
                                <label
                                  htmlFor="additional-patial"
                                  className="d-inline-block align-middle"
                                >
                                  추가로 일부가 변경
                                </label>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="siteprice-select-round d-block mt-3">
                              <h5 className="d-inline-block">절삭여부</h5>
                              <div className="siteprice-radio roundprice-radio">
                                <input
                                  id="roundRadio0"
                                  value="0"
                                  name="roundType"
                                  type="radio"
                                  checked={roundDownNow === 0}
                                  onChange={() => changeRoundDown(0)}
                                />
                                <label htmlFor="roundRadio0">안함</label>
                              </div>
                              {roundDown.map((r) => {
                                return (
                                  <div
                                    className="siteprice-radio roundprice-radio"
                                    key={r}
                                  >
                                    <input
                                      id={'roundRadio' + r}
                                      value={r}
                                      name="roundType"
                                      type="radio"
                                      checked={roundDownNow === r}
                                      onChange={() => changeRoundDown(r)}
                                    />
                                    <label htmlFor={'roundRadio' + r}>
                                      {r.toLocaleString()}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="contents-wrap x_panel">
                <div className="col-12">
                  <form className="form-horizontal form-label-left">
                    <div className="form-group">
                      <div className="row">
                        <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                          상품 가격 입력
                        </label>
                        <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                          {priceStep === 'preparing' && (
                            <div className="price-table-wrap">
                              <span>
                                옵션을 선택하고 가격 생성을 클릭 하세요.
                              </span>
                            </div>
                          )}
                          {priceStep === 'readyToMake' && (
                            <>
                              <SPPriceTable
                                from="setPrice"
                                totalCalc={totalCalc}
                                roundDownNow={roundDownNow}
                                latestColor={latestColor}
                                editInputs={editInputs}
                                setEditInputs={setEditInputs}
                                totalPatial={totalPatial}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  {editInputs.totalPriceCalc !== 'total' &&
                  editInputs.totalPriceCalc !== 'needSetting' ? (
                    <div
                      className="price-start-btn btn-inline-block"
                      data-toggle="tooltip"
                      onClick={() => {
                        saveNow('complete');
                      }}
                    >
                      <span>
                        <i className="fa fa-check" aria-hidden="true"></i>{' '}
                        수정완료
                      </span>
                    </div>
                  ) : (
                    <div className="price-start-btn btn-inline-block disabled">
                      <span>
                        <i className="fa fa-times" aria-hidden="true"></i>{' '}
                        {editInputs.totalPriceCalc === 'total'
                          ? '전체가 기준을 설정 하세요'
                          : '가격적용방식을 선택하세요'}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
