import { useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import axios from 'axios';
import { Site, WongoType } from '@interfaces/site.interface';
import { SERVER_URL } from '@config/path';
import { useLoading } from '@config/loadingContext';
import ModalEmpty from '@components/modal/ModalEmpty';
import SiteaddModal from '@components/modal/siteaddModal';
import DeleteModal from '@components/modal/deleteModal';
import WongoSiteModal from '@components/modal/wongoSiteModal';

export default function Sitelist() {
  /**선택한 메인 state*/
  const [siteList, setsiteList] = useState<Site[]>([]);

  /** 모달 state*/
  const [SaddModal, setSaddModal] = useState<boolean>(false);
  const closeModal = () => {
    setSaddModal(false);
  };
  const { loadingNow, loadingHandler } = useLoading();
  //const [siteList, setSiteList] = useState([]);
  const { payload, error } = useFetch<Site>(`site`, loadingNow);

  useEffect(() => {
    if (payload) {
      let copy: Site[] = payload.data;
      setsiteList(copy);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  /**
   * 기준 상품 수정하는 함수
   * @param sellerNameEN
   */

  const [editSiteId, setEditSiteId] = useState<string | undefined>('');
  const EditSiteE = (siteId: string | undefined) => {
    setEditSiteId(siteId);
    setSaddModal(true);
  };

  const siteDel = async (siteID: string | undefined) => {
    loadingHandler(true);
    confirm('삭제 하시겠습니까?')
      ? await axios
          .delete(SERVER_URL + '/site/' + siteID)
          .then((res) => {
            res.data.result == 'OK' ? alert('삭제 완료') : alert('삭제 취소');
            loadingHandler(false);
          })
          .catch((err) => {
            alert('삭제 실패');
            console.log(err);
            loadingHandler(false);
          })
      : '';
  };
  /**원고수정 */
  const [editWongoId, setEditWongoId] = useState<string | undefined>();
  const EditWongo = (siteId: string, data: WongoType[]) => {
    setEditWongoId(siteId);
    changeModal('wongo', true);
  };
  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */
  const [sortNow, setSortNow] = useState<{ [key: string]: boolean }>({
    topSeller: false,
    sellerName: false,
    siteUrl: false,
    IdNumber: false,
  });

  /**테이블 Sort Action */
  const sortBy = (key: string) => {
    let copy = [...siteList];
    copy.sort((a, b) =>
      !sortNow[key]
        ? String(a[key]) < String(b[key])
          ? -1
          : 1
        : String(a[key]) > String(b[key])
        ? -1
        : 1,
    );
    setSortNow({ ...sortNow, [key]: !sortNow[key] });
    setsiteList(copy);
    setSortColor(String([key]));
  };

  /** 딜리트 모달 */

  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    delete: false,
    wongo: false,
  });

  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };

  const [delID, setDelID] = useState<string>();
  const openDelConfirm = (id: string | undefined) => {
    setDelID(id);
    changeModal('delete', true);
  };
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-sitemap" />
            사이트 리스트 <small>사이트관리</small>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="x_content site-table-wrap">
              {siteList && (
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered GW-cont-head">
                    <thead>
                      <tr>
                        <th
                          className={`sortable cont-w150 ${
                            sortColor === 'topSeller' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('topSeller');
                          }}
                        >
                          판매업체 그룹{' '}
                          <span
                            className={`order-4 ${
                              sortNow['topSeller'] === true
                                ? 'setcolor'
                                : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th
                          className={`sortable ${
                            sortColor === 'sellerName' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('sellerName');
                          }}
                        >
                          판매업체{' '}
                          <span
                            className={`order-4 ${
                              sortNow['sellerName'] === true
                                ? 'setcolor'
                                : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th>사이트url</th>
                        <th
                          className={`sortable cont-w150 ${
                            sortColor === 'adminName' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('adminName');
                          }}
                        >
                          관리자이름{' '}
                          <span
                            className={`order-4 ${
                              sortNow['adminName'] === true
                                ? 'setcolor'
                                : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th
                          className={`sortable cont-w150 ${
                            sortColor === 'IdNumber' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('IdNumber');
                          }}
                        >
                          사번{' '}
                          <span
                            className={`order-4 ${
                              sortNow['IdNumber'] === true
                                ? 'setcolor'
                                : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        {/* <th className="cont-w150">원고작업자</th> */}
                        <th className="cont-w300">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {siteList.map((code, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{code.topSeller}</td>
                            <td>
                              {code.sellerName} ({code.sellerNameEN})
                            </td>
                            <td>
                              <a
                                href={'https://' + code.siteUrl}
                                target="blink"
                              >
                                {code.siteUrl}
                              </a>
                            </td>
                            <td>
                              {code.adminName} ({code.adminPosition})
                            </td>
                            <td>{code.IdNumber}</td>
                            {/* <td>
                              {code.wongo ? (
                                <span>
                                  {code.wongo
                                    .map((a) => {
                                      return a.name;
                                    })
                                    .join(', ')}
                                </span>
                              ) : (
                                <span>
                                  <i className="fa fa-times red"></i> 없음
                                </span>
                              )}
                            </td> */}
                            <td>
                              {/* <button
                                className="tableBtn tableBtn-green btnW-90 siteEventbtn"
                                onClick={() =>
                                  code._id && EditWongo(code._id, code.wongo)
                                }
                              >
                                원고담당자 수정
                              </button> */}
                              <button
                                className="tableBtn tableBtn-orange btnW-70 siteEventbtn"
                                onClick={() => EditSiteE(code._id)}
                              >
                                수정
                              </button>
                              <button
                                className="tableBtn tableBtn-red btnW-70 siteEventbtn"
                                onClick={() => openDelConfirm(code._id)}
                              >
                                삭제
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="sitemodal">
        {SaddModal && (
          <>
            <ModalEmpty close={closeModal} id={'sitelist'}>
              <SiteaddModal
                from="sitelist"
                siteId={editSiteId}
                closeModal={closeModal}
              />
            </ModalEmpty>
          </>
        )}
      </div>
      {modal.delete && delID && (
        <DeleteModal
          delID={delID}
          delEvents={siteDel}
          changeModal={changeModal}
        />
      )}{' '}
      {modal.wongo && editWongoId && (
        <WongoSiteModal editWongoId={editWongoId} changeModal={changeModal} />
      )}
    </>
  );
}
