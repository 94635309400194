import { NewAddwork } from '@interfaces/addwork.interface';
export const YAddworkDummy: NewAddwork = {
  _id: '6302fa4b6133026d11a6d5b2',
  productId: ['673444d1440052a449ed401c'],
  workType: 'numbering',
  groupCode: '넘버링',
  workTypeKO: '넘버링-마스터양식지',
  site: 'standard',
  selecters: [
    {
      title: '재질-OnlyPrice',
      codeCategory: 'materialCode',
      select: [
        {
          code: 'MAT:MOJ-70',
          title: '모조-70g',
          class: [],
          isHide: false,
          _id: '677f5171900a1dd3d3ab8fa7',
        },
        {
          code: 'MAT:MOJ-80',
          title: '모조-80g',
          class: [],
          isHide: false,
          _id: '677f5171900a1dd3d3ab8fa8',
        },
      ],
      _id: '677f5171900a1dd3d3ab8fa6',
    },
    {
      title: '사이즈-OnlyPrice',
      codeCategory: 'sizeCode',
      select: [
        {
          code: 'SIZ:NLM:95x130',
          title: '64절 95*130(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8faa',
        },
        {
          code: 'SIZ:NLM:190x85',
          title: '48절 85*190(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8fab',
        },
        {
          code: 'SIZ:NLM:190x130',
          title: '32절 130*190(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8fac',
        },
        {
          code: 'SIZ:NLM:190x260',
          title: '16절 190*260(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8fad',
        },
        {
          code: 'SIZ:NLM:105x148',
          title: 'A4(1/4) 105*148(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8fae',
        },
        {
          code: 'SIZ:NLM:210x99',
          title: 'A4(1/3) 99*210(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8faf',
        },
        {
          code: 'SIZ:NLM:210x148',
          title: 'A5 148*210(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8fb0',
        },
        {
          code: 'SIZ:NLM:210x297',
          title: 'A4 210*297(양식지)',
          class: [''],
          _id: '677f5171900a1dd3d3ab8fb1',
        },
      ],
      _id: '677f5171900a1dd3d3ab8fa9',
    },
    {
      title: '넘버링-개수',
      codeCategory: 'numberingCountCode',
      select: [
        {
          code: 'NBC:1',
          title: '1개',
          class: [],
          isHide: false,
          _id: '677f5171900a1dd3d3ab8fb3',
        },
        {
          code: 'NBC:2',
          title: '2개',
          class: [],
          isHide: false,
          _id: '677f5171900a1dd3d3ab8fb4',
        },
      ],
      _id: '677f5171900a1dd3d3ab8fb2',
    },
  ],
  price: [
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:95x130', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 99,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e60',
        },
        {
          min: 100,
          max: 149,
          value: 43000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e61',
        },
        {
          min: 150,
          max: 199,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e62',
        },
        {
          min: 200,
          max: 249,
          value: 66000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e63',
        },
        {
          min: 250,
          max: 299,
          value: 82000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e64',
        },
        {
          min: 300,
          max: 399,
          value: 98000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e65',
        },
        {
          min: 400,
          max: 499,
          value: 130000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e66',
        },
        {
          min: 500,
          max: 799,
          value: 153000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e67',
        },
        {
          min: 800,
          max: 999,
          value: 245000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e68',
        },
        {
          min: 1000,
          max: 999999,
          value: 299000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e69',
        },
      ],
      _id: '677f5171900a1dd3d3ab8e5f',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:95x130', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 79,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e6b',
        },
        {
          min: 80,
          max: 99,
          value: 40000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e6c',
        },
        {
          min: 100,
          max: 149,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e6d',
        },
        {
          min: 150,
          max: 199,
          value: 71000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e6e',
        },
        {
          min: 200,
          max: 249,
          value: 85000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e6f',
        },
        {
          min: 250,
          max: 299,
          value: 106000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e70',
        },
        {
          min: 300,
          max: 399,
          value: 127000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e71',
        },
        {
          min: 400,
          max: 499,
          value: 169000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e72',
        },
        {
          min: 500,
          max: 799,
          value: 199000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e73',
        },
        {
          min: 800,
          max: 999,
          value: 319000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e74',
        },
        {
          min: 1000,
          max: 999999,
          value: 388000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e75',
        },
      ],
      _id: '677f5171900a1dd3d3ab8e6a',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:190x85', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 99,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e77',
        },
        {
          min: 100,
          max: 149,
          value: 52000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e78',
        },
        {
          min: 150,
          max: 199,
          value: 68000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e79',
        },
        {
          min: 200,
          max: 249,
          value: 81000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e7a',
        },
        {
          min: 250,
          max: 299,
          value: 101000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e7b',
        },
        {
          min: 300,
          max: 399,
          value: 121000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e7c',
        },
        {
          min: 400,
          max: 499,
          value: 161000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e7d',
        },
        {
          min: 500,
          max: 799,
          value: 189000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e7e',
        },
        {
          min: 800,
          max: 999,
          value: 303000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e7f',
        },
        {
          min: 1000,
          max: 999999,
          value: 369000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e80',
        },
      ],
      _id: '677f5171900a1dd3d3ab8e76',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:190x85', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 49,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e82',
        },
        {
          min: 50,
          max: 79,
          value: 42000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e83',
        },
        {
          min: 80,
          max: 99,
          value: 50000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e84',
        },
        {
          min: 100,
          max: 149,
          value: 68000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e85',
        },
        {
          min: 150,
          max: 199,
          value: 88000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e86',
        },
        {
          min: 200,
          max: 249,
          value: 105000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e87',
        },
        {
          min: 250,
          max: 299,
          value: 131000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e88',
        },
        {
          min: 300,
          max: 399,
          value: 157000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e89',
        },
        {
          min: 400,
          max: 499,
          value: 209000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e8a',
        },
        {
          min: 500,
          max: 799,
          value: 246000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e8b',
        },
        {
          min: 800,
          max: 999,
          value: 393000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e8c',
        },
        {
          min: 1000,
          max: 999999,
          value: 480000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e8d',
        },
      ],
      _id: '677f5171900a1dd3d3ab8e81',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:190x130', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 49,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e8f',
        },
        {
          min: 50,
          max: 79,
          value: 42000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e90',
        },
        {
          min: 80,
          max: 99,
          value: 50000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e91',
        },
        {
          min: 100,
          max: 149,
          value: 69000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e92',
        },
        {
          min: 150,
          max: 199,
          value: 89000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e93',
        },
        {
          min: 200,
          max: 249,
          value: 106000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e94',
        },
        {
          min: 250,
          max: 299,
          value: 132000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e95',
        },
        {
          min: 300,
          max: 399,
          value: 158000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e96',
        },
        {
          min: 400,
          max: 499,
          value: 211000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e97',
        },
        {
          min: 500,
          max: 799,
          value: 248000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e98',
        },
        {
          min: 800,
          max: 999,
          value: 396000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e99',
        },
        {
          min: 1000,
          max: 999999,
          value: 483000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e9a',
        },
      ],
      _id: '677f5171900a1dd3d3ab8e8e',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:190x130', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 49,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e9c',
        },
        {
          min: 50,
          max: 79,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e9d',
        },
        {
          min: 80,
          max: 99,
          value: 65000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e9e',
        },
        {
          min: 100,
          max: 149,
          value: 89000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8e9f',
        },
        {
          min: 150,
          max: 199,
          value: 115000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea0',
        },
        {
          min: 200,
          max: 249,
          value: 137000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea1',
        },
        {
          min: 250,
          max: 299,
          value: 171000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea2',
        },
        {
          min: 300,
          max: 399,
          value: 206000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea3',
        },
        {
          min: 400,
          max: 499,
          value: 274000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea4',
        },
        {
          min: 500,
          max: 799,
          value: 322000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea5',
        },
        {
          min: 800,
          max: 999,
          value: 515000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea6',
        },
        {
          min: 1000,
          max: 999999,
          value: 628000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea7',
        },
      ],
      _id: '677f5171900a1dd3d3ab8e9b',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:190x260', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 39,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ea9',
        },
        {
          min: 40,
          max: 49,
          value: 45000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eaa',
        },
        {
          min: 50,
          max: 79,
          value: 76000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eab',
        },
        {
          min: 80,
          max: 99,
          value: 90000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eac',
        },
        {
          min: 100,
          max: 149,
          value: 124000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ead',
        },
        {
          min: 150,
          max: 199,
          value: 161000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eae',
        },
        {
          min: 200,
          max: 249,
          value: 192000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eaf',
        },
        {
          min: 250,
          max: 299,
          value: 240000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb0',
        },
        {
          min: 300,
          max: 399,
          value: 287000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb1',
        },
        {
          min: 400,
          max: 499,
          value: 283000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb2',
        },
        {
          min: 500,
          max: 799,
          value: 450000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb3',
        },
        {
          min: 800,
          max: 999,
          value: 720000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb4',
        },
        {
          min: 1000,
          max: 999999,
          value: 878000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb5',
        },
      ],
      _id: '677f5171900a1dd3d3ab8ea8',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:190x260', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 29,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb7',
        },
        {
          min: 30,
          max: 39,
          value: 44000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb8',
        },
        {
          min: 40,
          max: 49,
          value: 59000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eb9',
        },
        {
          min: 50,
          max: 79,
          value: 99000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eba',
        },
        {
          min: 80,
          max: 99,
          value: 117000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ebb',
        },
        {
          min: 100,
          max: 149,
          value: 161000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ebc',
        },
        {
          min: 150,
          max: 199,
          value: 209000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ebd',
        },
        {
          min: 200,
          max: 249,
          value: 279000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ebe',
        },
        {
          min: 250,
          max: 299,
          value: 311000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ebf',
        },
        {
          min: 300,
          max: 399,
          value: 373000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec0',
        },
        {
          min: 400,
          max: 499,
          value: 498000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec1',
        },
        {
          min: 500,
          max: 799,
          value: 585000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec2',
        },
        {
          min: 800,
          max: 999,
          value: 936000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec3',
        },
        {
          min: 1000,
          max: 999999,
          value: 1141000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec4',
        },
      ],
      _id: '677f5171900a1dd3d3ab8eb6',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:105x148', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec6',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec7',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec8',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ec9',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eca',
        },
      ],
      _id: '677f5171900a1dd3d3ab8ec5',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:105x148', 'NBC:2'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ecc',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ecd',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ece',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ecf',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed0',
        },
      ],
      _id: '677f5171900a1dd3d3ab8ecb',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:210x99', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 99,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed2',
        },
        {
          min: 100,
          max: 149,
          value: 49000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed3',
        },
        {
          min: 150,
          max: 199,
          value: 63000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed4',
        },
        {
          min: 200,
          max: 249,
          value: 75000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed5',
        },
        {
          min: 250,
          max: 299,
          value: 94000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed6',
        },
        {
          min: 300,
          max: 399,
          value: 112000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed7',
        },
        {
          min: 400,
          max: 499,
          value: 150000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed8',
        },
        {
          min: 500,
          max: 799,
          value: 176000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ed9',
        },
        {
          min: 800,
          max: 999,
          value: 281000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eda',
        },
        {
          min: 1000,
          max: 999999,
          value: 343000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8edb',
        },
      ],
      _id: '677f5171900a1dd3d3ab8ed1',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:210x99', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 79,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8edd',
        },
        {
          min: 80,
          max: 99,
          value: 46000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ede',
        },
        {
          min: 100,
          max: 149,
          value: 63000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8edf',
        },
        {
          min: 150,
          max: 199,
          value: 82000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee0',
        },
        {
          min: 200,
          max: 249,
          value: 97000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee1',
        },
        {
          min: 250,
          max: 299,
          value: 122000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee2',
        },
        {
          min: 300,
          max: 399,
          value: 146000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee3',
        },
        {
          min: 400,
          max: 499,
          value: 194000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee4',
        },
        {
          min: 500,
          max: 799,
          value: 229000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee5',
        },
        {
          min: 800,
          max: 999,
          value: 366000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee6',
        },
        {
          min: 1000,
          max: 999999,
          value: 446000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee7',
        },
      ],
      _id: '677f5171900a1dd3d3ab8edc',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:210x148', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 49,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ee9',
        },
        {
          min: 50,
          max: 79,
          value: 42000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eea',
        },
        {
          min: 80,
          max: 99,
          value: 50000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eeb',
        },
        {
          min: 100,
          max: 149,
          value: 69000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eec',
        },
        {
          min: 150,
          max: 199,
          value: 89000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eed',
        },
        {
          min: 200,
          max: 249,
          value: 106000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eee',
        },
        {
          min: 250,
          max: 299,
          value: 132000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eef',
        },
        {
          min: 300,
          max: 399,
          value: 158000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef0',
        },
        {
          min: 400,
          max: 499,
          value: 211000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef1',
        },
        {
          min: 500,
          max: 799,
          value: 248000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef2',
        },
        {
          min: 800,
          max: 999,
          value: 397000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef3',
        },
        {
          min: 1000,
          max: 999999,
          value: 483000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef4',
        },
      ],
      _id: '677f5171900a1dd3d3ab8ee8',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:210x148', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 49,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef6',
        },
        {
          min: 50,
          max: 79,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef7',
        },
        {
          min: 80,
          max: 99,
          value: 65000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef8',
        },
        {
          min: 100,
          max: 149,
          value: 81000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8ef9',
        },
        {
          min: 150,
          max: 199,
          value: 115000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8efa',
        },
        {
          min: 200,
          max: 249,
          value: 137000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8efb',
        },
        {
          min: 250,
          max: 299,
          value: 171000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8efc',
        },
        {
          min: 300,
          max: 399,
          value: 206000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8efd',
        },
        {
          min: 400,
          max: 499,
          value: 274000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8efe',
        },
        {
          min: 500,
          max: 799,
          value: 322000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8eff',
        },
        {
          min: 800,
          max: 999,
          value: 515000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f00',
        },
        {
          min: 1000,
          max: 999999,
          value: 628000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f01',
        },
      ],
      _id: '677f5171900a1dd3d3ab8ef5',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:210x297', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f03',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f04',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f05',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f06',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f07',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f02',
    },
    {
      code: ['MAT:MOJ-70', 'SIZ:NLM:210x297', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 29,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f09',
        },
        {
          min: 30,
          max: 39,
          value: 44000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f0a',
        },
        {
          min: 40,
          max: 49,
          value: 59000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f0b',
        },
        {
          min: 50,
          max: 79,
          value: 99000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f0c',
        },
        {
          min: 80,
          max: 99,
          value: 117000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f0d',
        },
        {
          min: 100,
          max: 149,
          value: 161000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f0e',
        },
        {
          min: 150,
          max: 199,
          value: 209000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f0f',
        },
        {
          min: 200,
          max: 249,
          value: 249000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f10',
        },
        {
          min: 250,
          max: 299,
          value: 311000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f11',
        },
        {
          min: 300,
          max: 399,
          value: 374000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f12',
        },
        {
          min: 400,
          max: 499,
          value: 473000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f13',
        },
        {
          min: 500,
          max: 799,
          value: 585000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f14',
        },
        {
          min: 800,
          max: 999,
          value: 936000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f15',
        },
        {
          min: 1000,
          max: 999999,
          value: 1141000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f16',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f08',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:95x130', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 99,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f18',
        },
        {
          min: 100,
          max: 149,
          value: 43000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f19',
        },
        {
          min: 150,
          max: 199,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f1a',
        },
        {
          min: 200,
          max: 249,
          value: 66000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f1b',
        },
        {
          min: 250,
          max: 299,
          value: 82000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f1c',
        },
        {
          min: 300,
          max: 399,
          value: 98000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f1d',
        },
        {
          min: 400,
          max: 499,
          value: 130000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f1e',
        },
        {
          min: 500,
          max: 799,
          value: 153000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f1f',
        },
        {
          min: 800,
          max: 999,
          value: 245000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f20',
        },
        {
          min: 1000,
          max: 999999,
          value: 298000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f21',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f17',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:95x130', 'NBC:2'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f23',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f24',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f25',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f26',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f27',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f22',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:190x85', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f29',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f2a',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f2b',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f2c',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f2d',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f28',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:190x85', 'NBC:2'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f2f',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f30',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f31',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f32',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f33',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f2e',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:190x130', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f35',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f36',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f37',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f38',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f39',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f34',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:190x130', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 49,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f3b',
        },
        {
          min: 50,
          max: 79,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f3c',
        },
        {
          min: 80,
          max: 99,
          value: 65000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f3d',
        },
        {
          min: 100,
          max: 149,
          value: 89000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f3e',
        },
        {
          min: 150,
          max: 199,
          value: 115000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f3f',
        },
        {
          min: 200,
          max: 249,
          value: 122000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f40',
        },
        {
          min: 250,
          max: 299,
          value: 171000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f41',
        },
        {
          min: 300,
          max: 399,
          value: 206000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f42',
        },
        {
          min: 400,
          max: 499,
          value: 274000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f43',
        },
        {
          min: 500,
          max: 799,
          value: 322000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f44',
        },
        {
          min: 800,
          max: 999,
          value: 515000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f45',
        },
        {
          min: 1000,
          max: 999999,
          value: 628000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f46',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f3a',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:190x260', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f48',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f49',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f4a',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f4b',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f4c',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f47',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:190x260', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 29,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f4e',
        },
        {
          min: 30,
          max: 39,
          value: 44000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f4f',
        },
        {
          min: 40,
          max: 49,
          value: 59000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f50',
        },
        {
          min: 50,
          max: 79,
          value: 99000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f51',
        },
        {
          min: 80,
          max: 99,
          value: 117000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f52',
        },
        {
          min: 100,
          max: 149,
          value: 161000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f53',
        },
        {
          min: 150,
          max: 199,
          value: 209000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f54',
        },
        {
          min: 200,
          max: 249,
          value: 249000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f55',
        },
        {
          min: 250,
          max: 299,
          value: 311000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f56',
        },
        {
          min: 300,
          max: 399,
          value: 361000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f57',
        },
        {
          min: 400,
          max: 499,
          value: 498000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f58',
        },
        {
          min: 500,
          max: 799,
          value: 585000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f59',
        },
        {
          min: 800,
          max: 999,
          value: 936000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f5a',
        },
        {
          min: 1000,
          max: 999999,
          value: 1141000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f5b',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f4d',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:105x148', 'NBC:1'],
      value: [
        {
          min: 10,
          max: 99,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f5d',
        },
        {
          min: 100,
          max: 149,
          value: 43000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f5e',
        },
        {
          min: 150,
          max: 199,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f5f',
        },
        {
          min: 200,
          max: 249,
          value: 66000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f60',
        },
        {
          min: 250,
          max: 299,
          value: 82000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f61',
        },
        {
          min: 300,
          max: 399,
          value: 98000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f62',
        },
        {
          min: 400,
          max: 499,
          value: 130000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f63',
        },
        {
          min: 500,
          max: 799,
          value: 153000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f64',
        },
        {
          min: 800,
          max: 999,
          value: 245000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f65',
        },
        {
          min: 1000,
          max: 999999,
          value: 300000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f66',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f5c',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:105x148', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 79,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f68',
        },
        {
          min: 80,
          max: 99,
          value: 39900,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f69',
        },
        {
          min: 100,
          max: 149,
          value: 55000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f6a',
        },
        {
          min: 150,
          max: 199,
          value: 71000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f6b',
        },
        {
          min: 200,
          max: 249,
          value: 85000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f6c',
        },
        {
          min: 250,
          max: 299,
          value: 106000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f6d',
        },
        {
          min: 300,
          max: 399,
          value: 127000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f6e',
        },
        {
          min: 400,
          max: 499,
          value: 169000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f6f',
        },
        {
          min: 500,
          max: 799,
          value: 199000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f70',
        },
        {
          min: 800,
          max: 999,
          value: 319000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f71',
        },
        {
          min: 1000,
          max: 999999,
          value: 388000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f72',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f67',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:210x99', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f74',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f75',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f76',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f77',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f78',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f73',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:210x99', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 79,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f7a',
        },
        {
          min: 80,
          max: 99,
          value: 46000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f7b',
        },
        {
          min: 100,
          max: 149,
          value: 63000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f7c',
        },
        {
          min: 150,
          max: 199,
          value: 82000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f7d',
        },
        {
          min: 200,
          max: 249,
          value: 97000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f7e',
        },
        {
          min: 250,
          max: 299,
          value: 122000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f7f',
        },
        {
          min: 300,
          max: 399,
          value: 146000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f80',
        },
        {
          min: 400,
          max: 499,
          value: 194000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f81',
        },
        {
          min: 500,
          max: 799,
          value: 229000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f82',
        },
        {
          min: 800,
          max: 999,
          value: 366000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f83',
        },
        {
          min: 1000,
          max: 999999,
          value: 445000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f84',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f79',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:210x148', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f86',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f87',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f88',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f89',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f8a',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f85',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:210x148', 'NBC:2'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f8c',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f8d',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f8e',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f8f',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f90',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f8b',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:210x297', 'NBC:1'],
      value: [
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f92',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f93',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f94',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f95',
        },
        {
          min: 0,
          max: 0,
          value: 0,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f96',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f91',
    },
    {
      code: ['MAT:MOJ-80', 'SIZ:NLM:210x297', 'NBC:2'],
      value: [
        {
          min: 10,
          max: 29,
          value: 39000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f98',
        },
        {
          min: 30,
          max: 39,
          value: 41000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f99',
        },
        {
          min: 40,
          max: 49,
          value: 59000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f9a',
        },
        {
          min: 50,
          max: 79,
          value: 99000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f9b',
        },
        {
          min: 80,
          max: 99,
          value: 117000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f9c',
        },
        {
          min: 100,
          max: 149,
          value: 161000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f9d',
        },
        {
          min: 150,
          max: 199,
          value: 209000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f9e',
        },
        {
          min: 200,
          max: 249,
          value: 249000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8f9f',
        },
        {
          min: 250,
          max: 299,
          value: 311000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8fa0',
        },
        {
          min: 300,
          max: 399,
          value: 373000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8fa1',
        },
        {
          min: 400,
          max: 499,
          value: 498000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8fa2',
        },
        {
          min: 500,
          max: 799,
          value: 585000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8fa3',
        },
        {
          min: 800,
          max: 999,
          value: 936000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8fa4',
        },
        {
          min: 1000,
          max: 999999,
          value: 1141000,
          calcValue: 0,
          _id: '677f5171900a1dd3d3ab8fa5',
        },
      ],
      _id: '677f5171900a1dd3d3ab8f97',
    },
  ],
  createdBy: 'subak008',
  createdAt: '2022-08-22T03:38:51.229Z',
  __v: 0,
  modifiedAt: '2025-01-09T04:32:49.337Z',
  modifiedBy: 'subak008',
};
