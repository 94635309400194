import React, { useState, useEffect, useCallback } from 'react';
import useFetch from '@utils/customHook/useFetch';
import Modal from '@components/modal/ModalComp';
import AddWorkModal from '@components/modal/addworkModal';
import { SERVER_URL } from '@config/path';
import moment from 'moment';
import Loglist from '@components/loglist';
import { IAddwork, IId, NewAddwork } from '@interfaces/addwork.interface';
import { ProductListType } from '@interfaces/tableCost.interfaces';
import { AddworkDummy } from '@dummydata/dummy';
import axios from 'axios';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import DeleteModal from '@components/modal/deleteModal';

function Addwork() {
  moment.locale();
  const { loadingNow, loadingHandler } = useLoading();
  const [productCheckList, setProductCheckList] = useState<IId>();
  const [id, setId] = useState<string>();

  const [newItem, setNewItem] = useState<NewAddwork>({ ...AddworkDummy });
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    check: false,
    log: false,
    new: false,
    edit: false,
  });
  const openNewModal = () => {
    setModal({ ...modal, new: !modal.new });
  };

  const openModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, addworkId: string) => {
      setAddWorkId(e.currentTarget.id, addworkId);
      const { id, value } = e.currentTarget;
      setModal({ ...modal, [id]: !modal[id] });
    },
    [modal],
  );
  const { payload, error } = useFetch<IAddwork>(`addwork/all`, loadingNow);

  const [productNames, setProductNames] = useState<ProductListType[]>([]);

  useEffect(() => {
    const getProductNames = async () => {
      await axios
        .get(`${SERVER_URL}/product`)
        .then((res) => {
          setProductNames(res.data.data);
        })
        .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    };
    getProductNames();
  }, []);
  const [iAddworkList, setIAddworkList] = useState<IAddwork[]>([]);
  const [iAddworkFilter, setIAddworkFilter] = useState<string[]>();
  const [iAddworkFilterNow, setIAddworkFilterNow] = useState<string>('전체');
  useEffect(() => {
    if (payload) {
      let copy = payload.data.filter((a) => a.site === 'standard');
      let filt = copy
        .filter((a) => a.groupCode)
        .map((b) => {
          return b.groupCode;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setIAddworkFilter(filt);
      const filtCopy = changeIAddworkFilter(iAddworkFilterNow, copy);
      nsortBy(nsortNow.type, nsortNow.key, filtCopy);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  const changeIAddworkFilter = (group: string, data: IAddwork[]) => {
    let copy = [...data];
    if (group !== '전체') {
      copy = copy.filter((a) => a.site === 'standard' && a.groupCode === group);
      setIAddworkList(copy);
    } else {
      copy = copy.filter((a) => a.site === 'standard');
      setIAddworkList(copy);
    }
    setIAddworkFilterNow(group);
    return copy;
  };
  const setAddWorkId = (target: string, addworkId: string) => {
    let copy = payload && payload.data.filter((a) => a.site === 'standard');
    let productId: string[] = [];
    copy &&
      copy.forEach((e) => e._id === addworkId && (productId = e.productId));
    target === 'log'
      ? setId(addworkId)
      : setProductCheckList({ addworkId, productId });
  };

  const closeModal = (target: string) => {
    loadingHandler(true);
    setModal({ ...modal, [target]: false });
    setNewItem({ ...AddworkDummy });
    loadingHandler(false);
  };
  const editAddwork = async (id: string) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/addwork/${id}`)
      .then((res) => {
        setNewItem(res.data.data);
        setModal({ ...modal, edit: true });
      })
      .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    loadingHandler(false);
  };

  const delAddwork = async (id: string) => {
    if (window.confirm(CONFIRM.DEL)) {
      loadingHandler(true);
      await axios
        .delete(`${SERVER_URL}/addwork/${id}`)
        .then((res) => {
          res.status === 204
            ? window.alert(MESSAGE.DELDONE)
            : window.alert(MESSAGE.CONFIRMAUTH);
        })
        .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
      loadingHandler(false);
    }
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'createdAt',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (type: boolean, key: string, data: IAddwork[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    copy.sort((a, b) => {
      if (!type) {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) < String(b[key]) ? -1 : 1;
      } else {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) > String(b[key]) ? -1 : 1;
      }
    });
    setIAddworkList(copy);
    setNsortNow({ ...nsortNow, type: type, key: key });
  };

  /** 삭제 모달 */

  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };

  const [delID, setDelID] = useState<string>();
  const openDelConfirm = (id: string) => {
    setDelID(id);
    changeModal('delete', true);
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-star" />
            후가공 <small>기준 판매 정보 등록</small>
            <div className="pull-right">
              <button
                className="topButtons purple"
                onClick={() => openNewModal()}
              >
                신규 후가공 등록
              </button>
            </div>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      {payload && (
        <div className="row">
          <div className="col-12">
            <div className="contents-wrap x_panel mb-2">
              <div className="groupFilter">
                <h5>후가공그룹</h5>
                <div className="groupFilter-wrap">
                  <div className="groupFilter-inner">
                    <div
                      className={`groupFilter-item ${
                        iAddworkFilterNow == '전체' ? 'active' : ''
                      }`}
                      onClick={() => changeIAddworkFilter('전체', payload.data)}
                    >
                      전체
                    </div>
                    {iAddworkFilter?.map((filt) => {
                      return (
                        <div
                          key={filt}
                          className={`groupFilter-item ${
                            iAddworkFilterNow == filt ? 'active' : ''
                          }`}
                          onClick={() =>
                            changeIAddworkFilter(filt, payload.data)
                          }
                        >
                          {filt}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="contents-wrap x_panel setETC-table-wrap table-wrap-green">
              <div className="x_content priceList-table-wrap">
                <div className="react-bootstrap-table">
                  <table
                    id="addwork-table"
                    className="table table-hover table-bordered"
                  >
                    <thead>
                      <tr>
                        <th
                          className={`sortable ${
                            sortColor === 'groupCode' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            nsortBy(!sortselect, 'groupCode', iAddworkList);
                          }}
                        >
                          후가공그룹명
                          <span
                            className={`order-4 ${
                              nsortNow.type === false ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th
                          className={`sortable ${
                            sortColor === 'workTypeKO' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            nsortBy(!sortselect, 'workTypeKO', iAddworkList);
                          }}
                        >
                          후가공명
                          <span
                            className={`order-4 ${
                              nsortNow.type === false ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th className="connectedProduct">적용상품수</th>
                        <th
                          className={`sortable ${
                            sortColor === 'createdAt' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            nsortBy(!sortselect, 'createdAt', iAddworkList);
                          }}
                        >
                          생성/수정
                          <span
                            className={`order-4 ${
                              nsortNow.type === false ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th className="">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {iAddworkList &&
                        iAddworkList.map((code, idx) => {
                          let time = moment(code.createdAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          let time2 = moment(code.modifiedAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          return (
                            <tr key={idx}>
                              <td>{code.groupCode}</td>
                              <td>{code.workTypeKO}</td>
                              <td>
                                {code.productId.length != 0 ? (
                                  <>
                                    <div className="productNameMatch-wrap  d-inline-block">
                                      <div className="productNameMatch">
                                        {code.productId.map((ID, idx2) => {
                                          let find = productNames.find(
                                            (a) => a._id === ID,
                                          );
                                          let match = find
                                            ? find.productNameKO
                                            : null;
                                          return (
                                            <React.Fragment key={idx2}>
                                              {idx2 !== 0 ? ', ' : ''}
                                              <span
                                                className={`${
                                                  match === null ? 'red' : ''
                                                }`}
                                              >
                                                {match === null ? (
                                                  <>
                                                    <i className="fa fa-times red"></i>
                                                    {' null'}
                                                  </>
                                                ) : (
                                                  match
                                                )}
                                              </span>
                                            </React.Fragment>
                                          );
                                        })}
                                      </div>

                                      <div className="productNameMatch-hover">
                                        {code.productId.map((ID, idx) => {
                                          let match = productNames.find(
                                            (a) => a._id == ID,
                                          )?.productNameKO;
                                          return <span key={idx}>{match}</span>;
                                        })}
                                        <h5 className="totalProductLength">
                                          - 총{' '}
                                          <span>{code.productId.length}</span>개
                                          상품
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="productNameMatch-wrap">
                                    <i className="fa fa-times red"></i> 적용상품
                                    없음
                                  </div>
                                )}
                              </td>
                              <td>
                                <div className="editDate-wrap">
                                  <div className="editDate-btn">보기</div>
                                  <div className="editDate-view">
                                    <div className="editDate-line">
                                      <h5>생성:</h5>
                                      <p className="standardproductID">
                                        {code.createBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time}
                                      </p>
                                    </div>
                                    <div className="editDate-line">
                                      <h5>수정:</h5>
                                      <p className="standardproductID">
                                        {code.modifiedBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time2}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button
                                  className="tableBtn tableBtn-purple btnW-80"
                                  id="log"
                                  onClick={(e) => openModal(e, code._id)}
                                >
                                  수정 로그
                                </button>
                                <button
                                  className="tableBtn tableBtn-orange btnW-70"
                                  id="modify"
                                  onClick={() => editAddwork(code._id)}
                                >
                                  수정
                                </button>
                                <button
                                  className="tableBtn tableBtn-red btnW-70"
                                  id="del"
                                  onClick={() => openDelConfirm(code._id)}
                                >
                                  삭제
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {modal.log && (
                <Modal close={closeModal} title={'로그 리스트'} id={'log'}>
                  <Loglist id={id} category="addwork" />
                </Modal>
              )}
              {modal.new && (
                <>
                  <Modal
                    close={closeModal}
                    title={'신규 후가공 등록'}
                    id={'new'}
                  >
                    <AddWorkModal
                      AddworkType="new"
                      newItem={newItem}
                      setNewItem={setNewItem}
                      closeModal={closeModal}
                      // megaData={megaData}
                      // megaData1st={megaData1st}
                    />
                  </Modal>
                </>
              )}
              {modal.edit && (
                <>
                  <Modal close={closeModal} title={'후가공 수정'} id={'edit'}>
                    <AddWorkModal
                      AddworkType="edit"
                      newItem={newItem}
                      setNewItem={setNewItem}
                      closeModal={closeModal}
                      // megaData={megaData}
                      // megaData1st={megaData1st}
                    />
                  </Modal>
                </>
              )}
              {modal.delete && delID && (
                <>
                  <DeleteModal
                    delID={delID}
                    delEvents={delAddwork}
                    changeModal={changeModal}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Addwork;
