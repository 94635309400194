import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput, Order } from '@interfaces/tableCost.interfaces';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import RenameModal from '@components/modal/renameModal';
import OptgroupModal from '@components/modal/optgroupModal';
import CodeReorder from '@components/table/standardproduct/reorder/code-reorder';
import OriginReorder from '@components/table/standardproduct/reorder/origin-reorder';
import { deleteEverything } from '@utils/common';
type AllModalType = {
  name: boolean;
  reorderAll: boolean;
  group: boolean;
};
interface Props {
  siteName: string;
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  changeModal: (type: string, bool: boolean) => void;
}
import { YUNeditdummy } from '@dummydata/yundummy';

export default function SPOptionModal({
  siteName,
  firstInputs,
  setFirstInputs,
  changeModal,
}: Props) {
  const { loadingHandler } = useLoading();
  /**전체모달 State */
  const [allModal, setAllModal] = useState<AllModalType>({
    name: false,
    reorderAll: false,
    group: false,
  });
  /** 모달닫기 */
  const allModalClose = () => {
    setAllModal({
      name: false,
      reorderAll: false,
      group: false,
    });
  };
  /** 모달핸들러 */
  const allModalHandler = (type: string, bool: boolean) => {
    allModalClose();
    setAllModal({ ...allModal, [type]: bool });
  };
  const reorderAllHandler = (bool: boolean) => {
    allModalClose();
    setAllModal({ ...allModal, reorderAll: bool });
  };
  /**
   * 옵션 변경 액션
   */
  const [OptionIndex, setOptionIndex] = useState<number>(0);
  /**옵션명 변경 */
  const [OptionNameArr, setOptionNameArr] = useState<Order[]>([
    ...firstInputs.selecters,
  ]);
  useEffect(() => {
    setOptionNameArr([...firstInputs.selecters]);
  }, [firstInputs.selecters]);
  const openNameEdit = (code: string) => {
    let IND = firstInputs.selecters.findIndex((a) => a.kindCode === code);
    setOptionIndex(IND);
    allModalHandler('name', true);
  };
  /**옵션순서 변경 */
  const [reorderModal, setReorderModal] = useState<boolean>(false);
  const openReorderModal = (code: string) => {
    let IND = firstInputs.selecters.findIndex((a) => a.kindCode === code);
    setOptionIndex(IND);
    setTimeout(() => {
      setReorderModal(true);
    }, 100);
  };
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    let copySelecters = [...firstInputs.selecters];
    if (type === 'select') {
      copySelecters[index].selecterName = value;
    } else {
      copySelecters[OptionIndex].types[index].title = value;
    }
    setFirstInputs((prev) => {
      return { ...firstInputs, selecters: copySelecters };
    });
  };
  /**
   * 옵션 순서 변경 액션
   * @param idxA 옵션 인덱스
   * @param idxB 옵션값 인덱스
   */
  const delOptionNow = (idxA: number, idxB: number) => {
    if (firstInputs.selecters[idxA].types.length < 2) {
      alert(CONFIRM.DELONLYONE);
      return;
    }
    if (window.confirm(CONFIRM.DELPERMENET)) {
      let copy = { ...firstInputs };
      copy.selecters[idxA].types.splice(idxB, 1);
      setFirstInputs(copy);
    }
  };
  /**
   * 옵션 순서 변경 액션
   * @param idxA 옵션 인덱스
   * @param idxB 옵션값 인덱스
   */
  const hideOptionNow = (idxA: number, idxB: number, bool: boolean) => {
    if (firstInputs.selecters[idxA].types.length < 2) {
      alert(CONFIRM.DELONLYONE);
      return;
    }

    if (bool === true) {
      if (!window.confirm(CONFIRM.HIDE)) {
        return;
      }
    }
    let copy = { ...firstInputs };
    copy.selecters[idxA].types[idxB].isHide = bool;
    setFirstInputs(copy);
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const saveNow = async (action: string) => {
    let productIdNow = firstInputs._id;
    let NewData = [...firstInputs.selecters].map((code) => {
      return deleteEverything<Order>(code, [
        '_id',
        'createdAt',
        'createdBy',
        'modifiedAt',
        'modifiedBy',
        '__v',
      ]);
    });

    loadingHandler(true);
    await axios
      .put(SERVER_URL + `/productbysite/${productIdNow}/update-selecters`, {
        tobeChange: NewData,
      })
      .then(function (response) {
        if (action === 'save') {
          alert(MESSAGE.SAVE);
        } else if (action === 'complete') {
          changeModal('option', false);
        }
        loadingHandler(false);
        alert(MESSAGE.SAVE);
      })
      .catch(function (error) {
        console.log('데이터 업로드에 실패 하였습니다.');
        loadingHandler(false);
      });
  };
  // const goDummy = () => {
  //   setFirstInputs({ ...YUNeditdummy });
  // };

  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal('option', false)}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-800">
        {/* <button onClick={() => goDummy()}>고더미</button> */}
        <div
          className="closeBtn"
          onClick={() => {
            changeModal('option', false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{siteName}</i>
                    </span>{' '}
                    - {firstInputs.productNameKO} 옵션 변경
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content minHeight300">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable rename"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>옵션명</th>
                              <th>주문옵션 코드값</th>
                            </tr>
                          </thead>
                          <tbody>
                            {firstInputs &&
                              firstInputs.selecters.map((code, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className="col-3 col-sm-4  col-xs-12">
                                      <input
                                        type="text"
                                        className="form-control renameTitle"
                                        placeholder="옵션 타이틀입력해주세요"
                                        name="selecterName"
                                        value={code.selecterName || ''}
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>,
                                        ) => handleChange('select', e, idx)}
                                        required
                                        autoFocus={true}
                                      />
                                    </td>
                                    <td className="col-9 col-sm-8 col-xs-12">
                                      <div className="option-input-group-wrap ub">
                                        <div className="input-group">
                                          <select
                                            className="form-control"
                                            name="types"
                                          >
                                            {firstInputs.selecters[
                                              idx
                                            ].types.map((b, i) => {
                                              return (
                                                <option key={b.code + '-' + i}>
                                                  {b.title}
                                                </option>
                                              );
                                            })}
                                          </select>
                                          {firstInputs.selecters[idx]
                                            .kindCode == 'materialCode' && (
                                            <div
                                              className="option-edit group ms-1"
                                              onClick={() =>
                                                allModalHandler('group', true)
                                              }
                                            >
                                              <i className="fa fa-group" />
                                            </div>
                                          )}
                                          <div
                                            className="option-edit name ms-1"
                                            onClick={() =>
                                              openNameEdit(code.kindCode)
                                            }
                                          >
                                            <i className="fa fa-edit" />
                                          </div>
                                          <div
                                            className="option-edit order ms-1"
                                            onClick={() => {
                                              setReorderModal(false);
                                              openReorderModal(code.kindCode);
                                            }}
                                          >
                                            <i className="fa fa-sort"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>

                  <div className="input-group-btn mt-3 mx-auto d-block text-center align-middle">
                    <div
                      className="yBtn yBtn-small orange text-center"
                      onClick={() => {
                        allModalHandler('reorderAll', true);
                      }}
                    >
                      <i className="fa fa-sort"></i> 옵션순서변경
                    </div>
                  </div>
                  {allModal.name && (
                    <RenameModal
                      firstInputs={firstInputs}
                      OptionIndex={OptionIndex}
                      OptionNameArr={OptionNameArr}
                      handleChange={handleChange}
                      delOptionNow={delOptionNow}
                      hideOptionNow={hideOptionNow}
                      allModalClose={allModalClose}
                    />
                  )}
                  {allModal.group && (
                    <OptgroupModal
                      firstInputs={firstInputs}
                      setFirstInputs={setFirstInputs}
                      allModalClose={allModalClose}
                    />
                  )}
                  {reorderModal && (
                    <CodeReorder
                      firstInputs={firstInputs}
                      setFirstInputs={setFirstInputs}
                      setReOrder={setReorderModal}
                      reOrderIndex={OptionIndex}
                    />
                  )}

                  {allModal.reorderAll && (
                    <OriginReorder
                      firstInputs={firstInputs}
                      setFirstInputs={setFirstInputs}
                      onSortOrign={reorderAllHandler}
                    />
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  <div
                    className="price-start-btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      saveNow('complete');
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      수정완료
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
