import React, { useState, useEffect } from 'react';
import { useFetchMega } from '@utils/customHook/useFetchCustom';
import { useAuthState } from '@config/reducer.index';
import CountUp from 'react-countup';
import moment from 'moment';
import HomeComponentCoupon from '@components/home/homeComponentCoupon';
import HomeComponentCustom from '@components/home/homeComponentCustom';
import {
  IHomeData,
  ISiteTotalSales,
  ICustomDate,
} from '@interfaces/home.interface';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';

import { NEST_SERVER_URL } from '@config/path';
import axios from 'axios';
import { ICoupon, ICouponData } from '@interfaces/tableCost.interfaces';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { getDate } from 'date-fns';
import { tr } from 'date-fns/locale';

registerLocale('ko', ko);
interface IProps {
  dataNow: IHomeData;
  idx: number;
}
export default function homeComponent({ dataNow, idx }: IProps) {
  const { user } = useAuthState();
  const [customShow, setCustomShow] = useState<boolean>(false);
  const [customDate, setCustomDate] = useState<ICustomDate>({
    from: moment().format('yyyy-MM-DDTHH:mm'),
    to: moment().format('yyyy-MM-DDTHH:mm'),
  });
  const ChangeDate = (types: string, date: Date | null, kind?: string) => {
    date !== null &&
      setCustomDate({
        ...customDate,
        [types]: moment(date).format(
          `yyyy-MM-DDT${
            kind === 'byTime' ? 'HH:mm' : types === 'from' ? '00:00' : '23:59'
          }`,
        ),
      });
  };
  const changeCustomShow = () => {
    setCustomShow(!customShow);
  };

  return (
    <>
      <div
        className={`col-12 ${
          dataNow.col !== 6 ? 'col-sm-6' : 'col-sm-12'
        } col-lg-${dataNow.col} mb-5 position-relative`}
      >
        <div
          className={`card salesCard ${dataNow.custom ? 'customCard' : ''} ${
            dataNow.custom && !customShow ? 'show' : 'hide'
          }`}
        >
          <div className="salesCard-body">
            <h4 className="salesCard-title">
              {dataNow.title}
              {dataNow.custom && !customShow && (
                <div
                  className="customChange pull-right"
                  onClick={() => changeCustomShow()}
                >
                  <i className="fa fa-refresh"></i>
                </div>
              )}
            </h4>
            <div className="salesCard-text">
              {dataNow.amount === undefined && dataNow.users === undefined ? (
                <div className="salesCard-loader">
                  <i className="fa fa-refresh"></i>
                </div>
              ) : dataNow.amount !== undefined ? (
                <>
                  <div className="salesCard-head">
                    <CountUp
                      className="salesCard-amount"
                      start={0}
                      end={dataNow.amount}
                      duration={0.5}
                      delay={0.25 * idx}
                      separator=","
                    />
                    <span className="unit">{' ' + dataNow.unit}</span>
                    {dataNow.amountAuto !== undefined && (
                      <p className="salesCard-amountAuto">
                        ({'무인 '}
                        <CountUp
                          start={0}
                          end={dataNow.amountAuto}
                          duration={0.5}
                          delay={0.25 * idx}
                          separator=","
                        />
                        <span>{' ' + dataNow.unit}</span>)
                      </p>
                    )}
                  </div>
                  {dataNow.subAmount !== undefined && (
                    <>
                      <div className="salesCard-foot">
                        <span className="salesCard-subTitle">
                          {dataNow.subTitle}
                        </span>
                        <CountUp
                          className="salesCard-amount"
                          start={0}
                          end={dataNow.subAmount}
                          duration={0.5}
                          delay={0.25 * idx}
                          separator=","
                        />
                        <span className="unit">{' ' + dataNow.unit}</span>
                      </div>

                      {user === 'e00055' &&
                        dataNow.subTitle?.includes('총매출') && (
                          <div className="hiddenCompare">
                            <span className="compare me-2">
                              {(dataNow.amount ? dataNow.amount : 0) -
                                (dataNow.subAmount ? dataNow.subAmount : 0) >
                              0 ? (
                                <i className="fa fa-arrow-up red" />
                              ) : (dataNow.amount ? dataNow.amount : 0) -
                                  (dataNow.subAmount ? dataNow.subAmount : 0) <
                                0 ? (
                                <i className="fa fa-arrow-down blue" />
                              ) : (
                                <i className="fa fa-arrow-right green" />
                              )}
                            </span>
                            <CountUp
                              className="salesCard-amount"
                              start={0}
                              end={
                                (dataNow.amount ? dataNow.amount : 0) -
                                (dataNow.subAmount ? dataNow.subAmount : 0)
                              }
                              duration={0.5}
                              delay={0.25 * idx}
                              separator=","
                            />
                            {' 원'}
                          </div>
                        )}
                    </>
                  )}
                </>
              ) : (
                <div className="userInfo-dash">
                  {dataNow.users && (
                    <table>
                      <tbody>
                        {dataNow.users.map((code, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <div className="userId">
                                  <h5>id:</h5> <span>{code.id}</span>
                                </div>
                                <div className="userName">
                                  <h5>회원명:</h5> <span>{code.name}</span>
                                </div>
                                <div className="userSales">
                                  <h5>매출액</h5>
                                  <span>
                                    &#8361;
                                    {code.totalSalesAmount.toLocaleString()}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
            <div className="salesCard-icon">
              <i className={'fa ' + dataNow.icon} />
            </div>
          </div>
        </div>
        {dataNow.custom && (
          <div
            className={`card salesCard customCard ${dataNow.custom} ${
              customShow ? 'show' : 'hide'
            }`}
          >
            <h4 className="salesCard-title">
              {dataNow.custom === 'byToday'
                ? 'Today 쿠폰사용 리스트'
                : dataNow.custom === 'byDay'
                ? '날짜셋팅매출'
                : '시간셋팅매출'}
              {customShow && (
                <div
                  className="customChange pull-right"
                  onClick={() => changeCustomShow()}
                >
                  <i className="fa fa-refresh"></i>
                </div>
              )}
            </h4>
            {customShow &&
              (dataNow.custom === 'byToday' ? (
                <HomeComponentCoupon dataNow={dataNow} />
              ) : (
                <HomeComponentCustom
                  customType={dataNow.custom}
                  customDate={customDate}
                  ChangeDate={ChangeDate}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
}
