import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { NEST_SERVER_URL, SERVER_URL } from '@config/path';
import { FirstInput, PopUp } from '@interfaces/tableCost.interfaces';
import S3ImageLoader from '@components/s3ImageLoader';
import { S3 } from 'aws-sdk';
import { useLoading } from '@config/loadingContext';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import NumberFormat from 'react-number-format';
import { Site } from '@interfaces/site.interface';
import { getYear } from 'date-fns';
import PopupLinkModal from '../popupLinkModal';

registerLocale('ko', ko);
/* eslint-disable @typescript-eslint/no-var-requires */ const _ = require('lodash');

interface Props {
  type: string;
  popupInputs: PopUp;
  setpopupInputs: Dispatch<SetStateAction<PopUp>>;
  closeModal: () => void;
  limitstyle: boolean;
  setlimitstyle: Dispatch<SetStateAction<boolean>>;
}
export default function PopupModal({
  type,
  popupInputs,
  setpopupInputs,
  closeModal,
  limitstyle,
  setlimitstyle,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();

  const getImgUrl = (element: { url: string; key: string }) => {
    handleimg(element.url);
  };

  //시간 날짜 설정
  const years = _.range(1990, getYear(new Date()) + 1, 1);

  const months = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];

  //사이트 리스트 불러오기

  const { payload, error } = useFetch<Site>(`site`, loadingNow);

  //팝업 인풋 값

  const handleSite = (name: string) => {
    let sitecopy = [...popupInputs.applySite];
    let result = sitecopy.includes(name)
      ? sitecopy.filter((data) => data !== name)
      : [...sitecopy, name];
    setpopupInputs({ ...popupInputs, applySite: result });
  };

  const [allSite, setAllSite] = useState<boolean>(false);

  const asite = payload?.data.map((list) => list.sellerNameEN);

  const handleSiteAll = () => {
    if (!allSite) {
      asite && setpopupInputs({ ...popupInputs, applySite: asite });
      setAllSite(true);
    } else {
      setpopupInputs({ ...popupInputs, applySite: [] });
      setAllSite(false);
    }
  };

  const hadleTotal = (name: string, value: string) => {
    if (name == 'title' || name == 'linkUrl') {
      setpopupInputs({ ...popupInputs, [name]: value });
    } else {
      let copy = popupInputs.location;
      let result = '';
      if (name == 'top') {
        result = value + '-' + copy.split('-')[1] + '-' + copy.split('-')[2];
      } else if (name == 'left') {
        result = copy.split('-')[0] + '-' + value + '-' + copy.split('-')[2];
      } else {
        result = copy.split('-')[0] + '-' + copy.split('-')[1] + '-' + value;
      }
      setpopupInputs({
        ...popupInputs,
        location: result,
      });
    }
  };
  const handleimg = (popUrl: string) => {
    setpopupInputs({ ...popupInputs, imgUrl: popUrl });
  };

  const saveNow = async (inputs: PopUp) => {
    loadingHandler(true);
    let popupCopy = { ...popupInputs };
    if (limitstyle === false) {
      popupCopy.sDate = '';
      popupCopy.eDate = '';
    }
    try {
      const response =
        type === 'new'
          ? await axios.post(NEST_SERVER_URL + '/popup', popupCopy)
          : await axios.put(
              NEST_SERVER_URL + '/popup/' + popupCopy._id,
              popupCopy,
            );
      if (response) {
        //popupalertCategorize(inputs) &&
        setpopupInputs(response.data.data);
        alert('등록성공');
        loadingHandler(false);
        type === 'edit' && closeModal();
      }
    } catch (e) {
      loadingHandler(false);
      alert('등록실패');
      console.log(e);
    }
    loadingHandler(false);
  };

  const ChangeDate = (types: string, date: Date | null) => {
    date !== null &&
      setpopupInputs({ ...popupInputs, [types]: date.toISOString() });
  };

  //링크 불러오기 모달
  const [linkModal, setLinkModal] = useState<boolean>(false);
  console.log('linkModal', linkModal);

  const closeLinkModal = () => setLinkModal(false);

  return (
    <>
      <div className="modalY-overlay" onClick={() => closeModal()}></div>
      <div id="standardproduct-window" className="preview-window ">
        <div
          className="closeBtn"
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {popupInputs && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>팝업 등록</h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>옵션명</th>
                              <th>등록할 팝업 옵션 선택</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">적용 사이트</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <div className="popupall_btn d-inline-block">
                                      <input
                                        id="all"
                                        className="d-inline-block align-middle"
                                        name="all-input"
                                        type="checkbox"
                                        checked={
                                          popupInputs.applySite.length ===
                                          asite?.length
                                            ? true
                                            : false
                                        }
                                        value={popupInputs.applySite}
                                        onChange={() => handleSiteAll()}
                                      />
                                      <label
                                        htmlFor="all"
                                        className="d-inline-block align-middle"
                                      >
                                        전체 선택
                                      </label>
                                    </div>
                                    {payload ? (
                                      payload.data.map((list) => (
                                        <React.Fragment key={list._id}>
                                          <input
                                            id={`${list.sellerNameEN}-input`}
                                            className="todayInputs"
                                            name={`${list.sellerNameEN}-input`}
                                            type="checkbox"
                                            checked={popupInputs.applySite.includes(
                                              list.sellerNameEN,
                                            )}
                                            onChange={() =>
                                              handleSite(list.sellerNameEN)
                                            }
                                          />
                                          <label
                                            htmlFor={`${list.sellerNameEN}-input`}
                                          >
                                            {list.sellerName}
                                          </label>
                                        </React.Fragment>
                                      ))
                                    ) : (
                                      <p>등록된 사이트가 없습니다.</p>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">제목</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <input
                                      className="nocInput popuptitle-input"
                                      type="text"
                                      value={popupInputs.title}
                                      onChange={(e) =>
                                        hadleTotal(
                                          e.target.name,
                                          e.target.value,
                                        )
                                      }
                                      name="title"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selecterName">팝업 이미지</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    <div className="noticeUp">
                                      <S3ImageLoader
                                        subCategories={'popup'}
                                        getImgUrl={getImgUrl}
                                      />
                                      <p>
                                        ※ 이미지의 용량은 00M이하로 등록해주시고
                                        가로최대 000px/세로최대 000px 이하로
                                        등록해주세요.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="popupimg-pv mt-2">
                                  <div className="noticeimg scroolBar">
                                    {popupInputs.imgUrl == '' ? (
                                      <p>팝업 미리보기</p>
                                    ) : (
                                      <img
                                        src={popupInputs.imgUrl}
                                        alt={popupInputs.imgUrl}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr></tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">링크 URL</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group input-group-no">
                                    <input
                                      type="button"
                                      className="link_btn"
                                      value="공지 링크 불러오기"
                                      onClick={() => setLinkModal(true)}
                                      name="linkBtn"
                                    />
                                    <input
                                      className="nocInput popuptitle-input"
                                      type="text"
                                      value={popupInputs.linkUrl}
                                      onChange={(e) =>
                                        hadleTotal(
                                          e.target.name,
                                          e.target.value,
                                        )
                                      }
                                      name="linkUrl"
                                    />
                                    <p>
                                      ※ 팝업을 클릭할때 연결하고 싶은 페이지의
                                      url을 드레그하여 복사/붙여넣기해주세요.
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">기간 설정</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group input-group-no">
                                    <div className="input-group">
                                      <input
                                        id="nolimit"
                                        name="limitbtn"
                                        type="radio"
                                        className="todayInputs"
                                        checked={!limitstyle}
                                        onChange={(e) => {
                                          setlimitstyle(false);
                                        }}
                                      />
                                      <label htmlFor="nolimit">제한없음</label>
                                      <input
                                        id="timelimit"
                                        name="limitbtn"
                                        type="radio"
                                        className="todayInputs"
                                        checked={limitstyle}
                                        onChange={(e) => {
                                          setlimitstyle(true);
                                        }}
                                      />
                                      <label htmlFor="timelimit">
                                        기간설정
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {limitstyle && (
                                  <div className="option-input-group-wrap ub mt-2">
                                    <div className="input-group input-group-no">
                                      <div className="">
                                        <DatePicker
                                          selected={
                                            popupInputs.sDate
                                              ? new Date(popupInputs.sDate)
                                              : new Date()
                                          }
                                          onChange={(date: Date | null) => {
                                            ChangeDate('sDate', date);
                                          }}
                                          showPopperArrow={false}
                                          fixedHeight
                                          selectsStart
                                          minDate={new Date()}
                                          locale={ko}
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          timeCaption="시작시간"
                                          dateFormat="yyyy-MM-dd   aa h:mm"
                                        />
                                      </div>
                                      <span className="date-m">~</span>
                                      <div className="">
                                        <DatePicker
                                          selected={
                                            popupInputs.eDate
                                              ? new Date(popupInputs.eDate)
                                              : new Date()
                                          }
                                          onChange={(date: Date | null) => {
                                            ChangeDate('eDate', date);
                                          }}
                                          fixedHeight
                                          selectsEnd
                                          minDate={new Date()}
                                          locale={ko}
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          timeCaption="종료시간"
                                          dateFormat="yyyy-MM-dd   aa h:mm"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">
                                  팝업 위치 설정
                                </div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group input-group-no">
                                    <p className="popuplocationP">위쪽여백</p>
                                    <NumberFormat
                                      className="nocInput popuplocationI"
                                      type="text"
                                      name="top"
                                      value={
                                        popupInputs.location.split('-')[0] !==
                                        '0'
                                          ? popupInputs.location.split('-')[0]
                                          : ''
                                      }
                                      onChange={(e) => {
                                        hadleTotal(
                                          e.target.name,
                                          e.target.value,
                                        );
                                      }}
                                      thousandSeparator
                                    />
                                    <p className="popuplocationPx">px</p>
                                    <p className="popuplocationP">왼쪽여백</p>
                                    <NumberFormat
                                      className="nocInput popuplocationI"
                                      type="text"
                                      name="left"
                                      value={
                                        popupInputs.location.split('-')[1] !==
                                        '0'
                                          ? popupInputs.location.split('-')[1]
                                          : ''
                                      }
                                      onChange={(e) => {
                                        hadleTotal(
                                          e.target.name,
                                          e.target.value,
                                        );
                                      }}
                                      thousandSeparator
                                    />
                                    <p className="popuplocationPx">px</p>
                                    <p className="popuplocationP">우선순위</p>
                                    <select
                                      className="pop-selcet"
                                      name="zindex"
                                      value={popupInputs.location.split('-')[2]}
                                      onChange={(e) => {
                                        hadleTotal(
                                          e.target.name,
                                          e.target.value,
                                        );
                                      }}
                                    >
                                      <option value="0">
                                        우선순위를 선택해주세요
                                      </option>
                                      <option value="500">낮음</option>
                                      <option value="1000">중간</option>
                                      <option value="1500">높음</option>
                                    </select>
                                  </div>
                                  <p className="popuplocation-text">
                                    ※ 기본위치는 0*0px로 팝업위치가 정해집니다.
                                    보기좋은 위치의 px값을 입력하세요.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row py-1">
                <div className="col-sm-12  text-center complete-btn-wrap my-3">
                  <div className=" mt-3 mb-3 mx-auto d-block text-center align-middle complete-btn-wrap">
                    <div
                      className="btn btn-success yBtn green yBtn-small d-inline-block"
                      onClick={() => {
                        saveNow(popupInputs);
                      }}
                    >
                      {type === 'new' ? (
                        <>
                          <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                          등록완료
                        </>
                      ) : (
                        <>
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          수정완료
                        </>
                      )}
                    </div>
                    <div
                      className="btn yBtn yBtn-small btn btn-inline-block"
                      data-toggle="tooltip"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <span>
                        <i className="fa fa-check" aria-hidden="true"></i> 닫기
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {linkModal && (
          <>
            <PopupLinkModal
              closeLinkModal={closeLinkModal}
              popupInputs={popupInputs}
              setpopupInputs={setpopupInputs}
            />
          </>
        )}
      </div>
    </>
  );
}
