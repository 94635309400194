/* typescript-eslint-disable no-implicit-any */
import { useRef } from 'react';
import AWS, { S3 } from 'aws-sdk';
import { resolve } from 'path';
import React, { useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import { NEST_SERVER_URL } from '@config/path';
interface ReturnType {
  data: {
    url: string;
    key: string;
  };
}
interface IProps {
  TypeNow?: string;
  subCategories: 'notice' | 'product' | 'popup' | 'notification' | 'wongo';
  getImgUrl: (
    res: {
      url: string;
      key: string;
    },
    str?: string,
  ) => void;
}
/**
 * 프롭스로 데이터를 받아 s3안에 자동 정렬
 * @props siteName :"printcity" | "imadesign"
 * @props subCategories :"notice" | "product"
 * @props broadCategory :"background" | "main" | "small" | "medium" | undefined
 * @returns 저장된 url,파일이름등
 */
export default function s3ImageLoader({
  TypeNow,
  subCategories,
  getImgUrl,
}: IProps) {
  const fileInput = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const boardServerAxios: AxiosInstance = axios.create({
    baseURL: NEST_SERVER_URL,
  });
  const handleFileInput = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setSelectedFile(file);
    target.value = '';
  };

  const submitHandler = () => {
    if (!selectedFile) {
      alert('파일을 먼저 선택하세요.');
      return;
    }
    uploadFile(selectedFile);
  };
  const uploadFile = (file: File) => {
    //formdata 만드는 부분
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'fileKey',
      `images/${subCategories}/ADMIN_${new Date().getTime()}_${file.name}`,
    );
    boardServerAxios
      .post<FormData, ReturnType>('/file-upload', formData)
      .then((res) => {
        if (subCategories === 'notice') {
          alert('파일이 업로드 되었습니다.');
          getImgUrl(res.data, TypeNow);
        } else {
          alert('파일이 업로드 되었습니다.');
          getImgUrl(res.data);
        }
      })
      .catch((err) => {
        alert('파일 업로드 실패했습니다. 관리자에게 문의하세요.');
      });
    // myBucket
    //   .upload(params)
    //   .promise()
    //   .then((data) => {
    //     if (subCategories === 'notice') {
    //       alert('파일이 업로드 되었습니다.');
    //       getImgUrl(data, TypeNow);
    //     } else {
    //       alert('파일이 업로드 되었습니다.');
    //       getImgUrl(data);
    //     }
    //   })
    //   .catch((err) => {
    //     alert('파일 업로드 실패했습니다. 관리자에게 문의하세요.');
    //     clearFile();
    //     console.log(err);
    //   });
  };
  if (subCategories === 'notice') {
    return (
      <>
        <span className="filename">
          {!selectedFile ? '파일을 업로드해주세요' : selectedFile.name}
        </span>
        <label htmlFor={'s3btn' + TypeNow} className="s3btn-label">
          <input
            ref={fileInput}
            id={'s3btn' + TypeNow}
            className="S3-btn"
            type="file"
            accept="image/*"
            onChange={handleFileInput}
          />
        </label>
        <div className="S3-confirm" onClick={() => submitHandler()}>
          확인
        </div>
      </>
    );
  } else {
    return (
      <>
        <span className="filename">
          {!selectedFile ? '파일을 업로드해주세요' : selectedFile.name}
        </span>
        <label htmlFor={'s3btn'} className="s3btn-label">
          <input
            id={'s3btn'}
            className="S3-btn"
            type="file"
            accept="image/*"
            onChange={handleFileInput}
          />
        </label>
        <div className="S3-confirm" onClick={() => submitHandler()}>
          확인
        </div>
      </>
    );
  }
}
