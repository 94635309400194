import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import sitealertCategorize from '@utils/sitealertFunc';
import { Site } from '@interfaces/site.interface';
import { useLoading } from '@config/loadingContext';
import useFetchGetone from '@utils/customHook/useFetchGetone';
import { SiteaddIn } from '@dummydata/dummy';

interface Props {
  from: string;
  siteId: string | undefined;
  closeModal: () => void;
}

export default function SiteaddModal({ from, siteId, closeModal }: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  /**
   * input(카테고리, 상품옵션, 판매상태) onchange 이벤트
   * @param name input name
   * @param value input value
   */

  //인풋 값

  const [siteInputs, setsiteInputs] = useState<Site>({
    ...SiteaddIn,
  });

  const { payload: editPayload } = useFetchGetone<Site>(
    `site/${siteId}`,
    siteId,
  );

  useEffect(() => {
    if (editPayload) {
      setsiteInputs(editPayload.data);
    }
  }, [editPayload]);

  const siteChange = (name: string, value: string | boolean) => {
    setsiteInputs({ ...siteInputs, [name]: value });
  };

  const siteSave = async (input: Site) => {
    loadingHandler(true);
    let siteCopy = {
      topSeller: siteInputs.topSeller,
      sellerName: siteInputs.sellerName,
      sellerNameEN: siteInputs.sellerNameEN,
      siteUrl: siteInputs.siteUrl,
      adminName: siteInputs.adminName,
      adminPosition: siteInputs.adminPosition,
      IdNumber: siteInputs.IdNumber,
      pw: siteInputs.pw,
    };
    let siteNow = siteInputs._id;
    sitealertCategorize(input) &&
      (await axios
        .put(SERVER_URL + '/site/' + siteNow, siteCopy)
        .then((response) => {
          setsiteInputs(response.data.data);
          loadingHandler(false);
          closeModal();
          alert('사이트 수정 성공');
        })
        .catch(function (error) {
          console.log('데이터 업로드에 실패 하였습니다.');
          loadingHandler(false);
          alert('사이트 수정 실패');
        }));
  };

  const coOptions = [
    { value: '프린트시티그룹', name: '프린트시티그룹' },
    { value: '아이엠디자인그룹', name: '아이엠디자인그룹' },
  ];

  const rankOptions = [
    { value: '부장', name: '부장' },
    { value: '차장', name: '차장' },
    { value: '과장', name: '과장' },
  ];

  return (
    <>
      <div id={from + '-window'}>
        {siteInputs && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    사이트 등록 수정<small>Data Inputs</small>
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content siteadd_wrap">
                  <form
                    id="demo-form2"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                          htmlFor="groupSite"
                        >
                          최상위 판매업체선택
                        </label>
                        <div className="cost-cont cost-cont col-md-2 col-sm-2 col-xs-12">
                          <select
                            className="form-control site-selcet"
                            onChange={(
                              ev: React.ChangeEvent<HTMLSelectElement>,
                            ) => siteChange(ev.target.name, ev.target.value)}
                            name="topSeller"
                            value={siteInputs && siteInputs.topSeller}
                            required
                          >
                            {coOptions.map((option) => {
                              return (
                                <option key={option.value} value={option.value}>
                                  {option.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                          htmlFor="siteName"
                        >
                          기본 판매업체 명
                        </label>
                        <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                          <div className="row">
                            <label
                              className="control-label cost-text col-md-1 col-sm-1 col-xs-12"
                              htmlFor="siteName"
                            >
                              업체명
                            </label>
                            <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) =>
                                  siteChange(e.target.name, e.target.value)
                                }
                                name="sellerName"
                                value={
                                  (siteInputs && siteInputs.sellerName) || ''
                                }
                              />
                            </div>
                            <label
                              className="control-label cost-text col-md-1 col-sm-1 col-xs-12"
                              htmlFor="siteName"
                            >
                              업체영문명
                            </label>
                            <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) =>
                                  siteChange(e.target.name, e.target.value)
                                }
                                name="sellerNameEN"
                                value={
                                  (siteInputs && siteInputs.sellerNameEN) || ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label
                          htmlFor="siteAdress"
                          className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                        >
                          사이트 url
                        </label>
                        <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) =>
                              siteChange(e.target.name, e.target.value)
                            }
                            name="siteUrl"
                            value={(siteInputs && siteInputs.siteUrl) || ''}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label
                          htmlFor="numProduct"
                          className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                        >
                          관리자 이름
                        </label>
                        <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) =>
                              siteChange(e.target.name, e.target.value)
                            }
                            name="adminName"
                            value={(siteInputs && siteInputs.adminName) || ''}
                          />
                        </div>
                        <div className="cost-cont cost-cont col-md-2 col-sm-2 col-xs-12">
                          <select
                            className="form-control site-selcet"
                            onChange={(
                              ev: React.ChangeEvent<HTMLSelectElement>,
                            ) => siteChange(ev.target.name, ev.target.value)}
                            name="adminPosition"
                            value={siteInputs && siteInputs.adminPosition}
                            required
                          >
                            <option value="empty">직급을 선택하세요</option>
                            {rankOptions.map((option) => {
                              return (
                                <option key={option.value} value={option.value}>
                                  {option.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label
                          htmlFor="numProduct"
                          className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                        >
                          사원번호
                        </label>
                        <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) =>
                              siteChange(e.target.name, e.target.value)
                            }
                            name="IdNumber"
                            value={(siteInputs && siteInputs.IdNumber) || ''}
                          />
                        </div>
                        <p className="cost-text col-md-3 col-sm-3 col-xs-12">
                          ※ 기존 사용하는 사원번호와 비번을 그대로 사용하는걸
                          권장합니다.
                        </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label
                          htmlFor="numProduct"
                          className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                        >
                          비밀번호
                        </label>
                        <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) =>
                              siteChange(e.target.name, e.target.value)
                            }
                            name="pw"
                            value={(siteInputs && siteInputs.pw) || ''}
                          />
                        </div>
                        <p className="cost-text col-md-3 col-sm-3 col-xs-12">
                          ※ 기존 사용하는 사원번호와 비번을 그대로 사용하는걸
                          권장합니다.
                        </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className=" mt-3 mb-3 mx-auto d-block text-center align-middle complete-btn-wrap">
                        <div
                          className="btn btn-success yBtn green yBtn-small d-inline-block"
                          onClick={() => siteSave(siteInputs)}
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>
                          수정 완료
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
