import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import CSS from 'csstype';
import axios, { AxiosRequestConfig } from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput, IWongoSelect } from '@interfaces/tableCost.interfaces';
import { S3 } from 'aws-sdk';
import { useLoading } from '@config/loadingContext';
import NumberFormat from 'react-number-format';
import useFetchGetone from '@utils/customHook/useFetchGetone';
import useFetchNoData from '@utils/customHook/useFetchNoData';

interface Props {
  siteName: string;
  firstInputs: FirstInput;
  closeModal: () => void;
}
export default function wongoPriceModal({
  siteName,
  firstInputs,
  closeModal,
}: Props) {
  const { loadingHandler } = useLoading();

  const [wongoON, setWongoON] = useState<boolean>(false);
  const singleDummy = [
    { title: '단면(전체디자인)', code: 'SingleFull', value: 0 },
    { title: '단면(부분수정)', code: 'SinglePartial', value: 0 },
  ];
  const doubleDummy = [
    { title: '양면(전체디자인)', code: 'DoubleFull', value: 0 },
    { title: '양면(부분수정)', code: 'DoublePartial', value: 0 },
  ];
  const mapDummy = [{ title: '약도추가비', code: 'MapAdd', value: 0 }];
  const colorFilter = firstInputs.selecters.filter(
    (a) => a.kindCode === 'colorCode',
  );
  const colorCodes: string[] = colorFilter[0]
    ? colorFilter[0].types.map((a) => a.code)
    : [];
  const [wongoInputs, setWongoInputs] = useState<IWongoSelect[]>([]);
  useEffect(() => {
    if (firstInputs.wongoSelect && firstInputs.wongoSelect.length > 0) {
      setWongoON(true);
      setWongoInputs([...firstInputs.wongoSelect]);
    }
  }, [firstInputs]);
  const wongoONHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setWongoON(checked);
    setWongoInputs(
      checked
        ? colorCodes &&
          colorCodes.some((b) => ['44', '41', '11'].some((c) => b.includes(c)))
          ? [...singleDummy, ...doubleDummy, ...mapDummy]
          : [...singleDummy, ...mapDummy]
        : [],
    );
  };
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleInput = (val: string, idx: number) => {
    const copy = [...wongoInputs];
    copy[idx].value = Number(val.replace(' 원', '').replace(/,/g, ''));
    setWongoInputs(copy);
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const saveNow = async () => {
    let finalCopy = { wongoSelect: wongoInputs };
    const requestOptions: AxiosRequestConfig = {
      url: SERVER_URL + '/productbysite/' + firstInputs._id,
      data: finalCopy,
      method: 'PUT',
    };
    try {
      const response = await axios(requestOptions);
      if (response) {
        alert('저장완료');
        closeModal();
      }
      loadingHandler(false);
    } catch (e) {
      alert('저장실패!');
      loadingHandler(false);
    }
  };
  return (
    <>
      <div className="modalY-overlay" onClick={() => closeModal()}></div>
      <div id="standardproduct-window" className="preview-window preview-600">
        <div
          className="closeBtn"
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    원고 가격표 설정
                    <span>
                      {' <'}
                      {siteName} - {firstInputs.productNameKO}
                      {'>'}
                    </span>
                  </h4>
                  <div className="text-end">
                    <div className="align-middle d-inline-block me-2">
                      <div className="selecterName">원고 ON/OFF</div>
                    </div>
                    <div className="d-inline-block">
                      <label className="salesSwitch  wongoSwitch">
                        <input
                          type="checkbox"
                          checked={wongoON}
                          onChange={(e) => wongoONHandler(e)}
                        />
                        <span className="slider round"></span>
                        <p className="pNow">
                          {wongoON ? '원고있음' : '원고없음'}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable rename"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>옵션명</th>
                              <th>주문옵션 코드값</th>
                            </tr>
                          </thead>
                          <tbody>
                            {wongoInputs && wongoInputs.length > 0 ? (
                              wongoInputs.map((code, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className="col-4 col-xs-12">
                                      <label>{code.title}</label>
                                    </td>
                                    <td className="col-8 col-xs-12">
                                      <NumberFormat
                                        type="text"
                                        className="form-control renameTitle"
                                        style={{ textAlign: 'right' }}
                                        placeholder="가격을 입력해주세요"
                                        name="value"
                                        suffix={' 원'}
                                        value={code.value}
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>,
                                        ) => handleInput(e.target.value, idx)}
                                        autoComplete="off"
                                        thousandSeparator
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={2} className="col-4 col-xs-12">
                                  원고없음
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row py-1">
                <div className="col-sm-12  text-center complete-btn-wrap my-3">
                  <div
                    className="yBtn yBtn-xl purple btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      saveNow();
                    }}
                  >
                    <span>
                      <i className="fa fa-plus" aria-hidden="true"></i> 등록하기
                    </span>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
