import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { History } from 'history';
import Cookies from 'universal-cookie';
import type { AuthDispatch, Tuser } from '@interfaces/globals.types';
import { onLoginSuccess } from '@utils/login.utils';
import { NEST_SERVER_URL, SERVER_URL } from '@config/path';
import { RouteComponentProps } from 'react-router-dom';
import { removeCookie, setCookie } from '@config/cookies';

type LoginPayload = { id: string; pwd: string };

export const loginUser = async (
  dispatch: AuthDispatch,
  loginPayload: LoginPayload,
  history: RouteComponentProps['history'],
) => {
  const requestOptions: AxiosRequestConfig = {
    url: `${SERVER_URL}/auth/signin`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: loginPayload,
  };

  try {
    const response = await axios(requestOptions);
    const { id, userName, userClass, occupation, siteFilter } = JSON.parse(
      response.config.data,
    );
    if (response.status === 200) {
      onLoginSuccess(response);
      dispatch({
        type: 'LOGIN_SUCCESS',
        user: id,
        userName: userName,
        userClass: userClass,
        occupation: occupation,
        siteFilter: siteFilter,
        loading: false,
      });
      return response.data;
    } else {
      dispatch({
        type: 'LOGIN_ERROR',
        errorMessage: response.data.error[0],
        loading: false,
      });
    }
    return;
  } catch (e) {
    dispatch({
      type: 'LOGIN_ERROR',
      errorMessage: String(e),
      loading: false,
    });
    alert('아이디나 비밀번호가 맞지 않습니다. 관리자에게 문의하세요.');
  }
};

export const refresh = async (
  dispatch: AuthDispatch,
  loginhandler: (login: boolean) => void,
  history: RouteComponentProps['history'],
) => {
  await axios
    .get(`${SERVER_URL}/auth/verifyrefreshtoken`, {
      headers: {
        'Content-Type': 'application/json',
        // cookies: `${refreshToken}`,
      },
      withCredentials: true,
    })
    .then(async (res) => {
      const { accessToken } = res.data;
      axios.defaults.headers.common['Authorization'] = accessToken;
      dispatch({
        type: 'LOGIN_SUCCESS',
        user: res.data.userId,
        userName: res.data.userName,
        userClass: res.data.userClass,
        occupation: res.data.occupation,
        siteFilter: res.data.siteFilter,
        loading: false,
      });
      loginhandler(true);
    })
    .catch((err) => {
      logout(dispatch);
      loginhandler(false);
      history.push('/login');
    });
};

export async function logout(dispatch: AuthDispatch) {
  await axios
    .post(
      `${NEST_SERVER_URL}/auth/logout`,
      {},
      {
        withCredentials: true,
      },
    )
    .then((res) => {
      dispatch({
        type: 'LOGOUT',
        user: '',
        userName: '',
        userClass: '',
        occupation: '',
        siteFilter: '',
      });
      axios.defaults.headers.common['Authorization'] = '';
      return res.data;
    })
    .catch((err) => {
      console.log(err.response);
    });
}
export const getUser = async (): Promise<Tuser[]> => {
  const requestOptions: AxiosRequestConfig = {
    url: `${SERVER_URL}/user`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  try {
    const response = await axios(requestOptions);
    if (response) {
      return response.data;
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const getOneUser = async (idNow: string): Promise<Tuser | null> => {
  const requestOptions: AxiosRequestConfig = {
    url: `${SERVER_URL}/user?id=${idNow}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  try {
    const response = await axios(requestOptions);
    if (response) {
      return response.data;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const changeUserDegree = (code: string, sub?: string): string => {
  switch (code) {
    case 'firstDegreeManager':
      return '최고 관리자';
    case 'general manager':
      return '사이트 관리자';
    case 'common manager':
      if (sub === 'wongoManager') {
        return '원고 관리자';
      } else if (sub === 'fileManager') {
        return '파일 관리자';
      } else {
        return '일반 관리자';
      }
    default:
      return '직원';
  }
};

export const changeSiteCode = (
  code: string,
): { name: string; code: string; link: string } => {
  switch (code) {
    case 'printcity':
    case 'PC':
      return {
        name: '프린트시티',
        code: 'PC',
        link: 'https://www.printcity.co.kr/',
      };
    case 'ecard21':
    case 'EC':
      return {
        name: '명함천국',
        code: 'EC',
        link: 'https://www.ecard21.co.kr/',
      };
    case 'iamdesign':
    case 'IM':
      return {
        name: '아이엠디자인',
        code: 'IM',
        link: 'https://www.iamdgn.com/',
      };
    case 'megaprint':
    case 'MP':
      return {
        name: '메가프린트',
        code: 'MP',
        link: 'https://www.megaprint.co.kr/',
      };
    case 'speedcolor':
    case 'SP':
      return {
        name: '스피드칼라',
        code: 'SP',
        link: 'https://www.speedcolor.co.kr/',
      };

    case 'speedshooter':
    case 'SS':
      return {
        name: '스피드칼라슈터',
        code: 'SS',
        link: '',
      };
    default:
      return { name: '알수없음', code: 'null', link: 'null' };
  }
};
