import { useState, useEffect } from 'react';
import { useLoading } from '@config/loadingContext';
import { IWongoItem } from '@interfaces/tableCost.interfaces';
import moment from 'moment';
import WongoListModal from '@components/modal/wongoListModal';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import BoardPagination from '@utils/boardPagination';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import axios, { AxiosRequestConfig } from 'axios';
import { NEST_SERVER_URL, SERVER_URL } from '@config/path';
import { useAuthState } from '@config/context';
import { changeSiteCode } from '@config/actions';
import { NumberCapability } from 'aws-sdk/clients/pinpointsmsvoicev2';
import { Tuser } from '@interfaces/globals.types';
import useFetch from '@utils/customHook/useFetch';
import { Site } from '@interfaces/site.interface';

export interface boardDateFixed {
  start: string;
  end: string;
}

export default function SetWongo() {
  const { user, userName, userClass, occupation, siteFilter } = useAuthState();
  const [isWongoManager, setIsWongoManager] = useState<boolean>(false);
  useEffect(() => {
    if (occupation) {
      setIsWongoManager(occupation === 'wongoManager' ? true : false);
    }
  }, [occupation]);

  /* 사이트 필터 */
  const changeSiteFilter = async (str: string) => {
    setSiteFilterNow(str === '' ? '전체' : str);
    setReload(false);
    setPaging(1);
  };

  const [siteList, setSiteList] = useState<Site[]>([]);
  const [siteFilterNow, setSiteFilterNow] = useState<string>('all');
  const { payload: sitePayload } = useFetch<Site>(`site`, true);

  useEffect(() => {
    if (sitePayload) {
      let copy: Site[] = sitePayload.data;
      setSiteList(copy);
    } else {
      console.log('payload yet!');
    }
  }, [sitePayload]);

  /* 유저 ID 검색 */
  /* 인풋 */
  /* ID 확정 검색용 */
  const [search, setSearch] = useState<{ key: string; val: string }>({
    key: '',
    val: '',
  });
  const [searchNow, setSearchNow] = useState<{ key: string; val: string }>();

  const searchHandle = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setSearch((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const searchClick = () => {
    setSearchNow({ ...search });
    setReload(!reload);
  };
  const [paging, setPaging] = useState<number>(1); //현재 페이지
  const [reload, setReload] = useState<boolean>(false);

  /* 날짜 검색*/

  const [dateCustom, setDateCustom] = useState<boardDateFixed>({
    start: moment().format('yyyy-MM-DD 00:00:00.000'),
    end: moment().format('yyyy-MM-DD 23:59:59.599'),
  });

  const [dateFixed, setDateFixed] = useState<boardDateFixed>({
    start: '',
    end: '',
  });

  const [dateCal, setDateCal] = useState<boolean>(false);

  const ChangeDate = (types: string, date: Date | null) => {
    date !== null &&
      setDateCustom({
        ...dateCustom,
        [types]: moment(date).format(
          `yyyy-MM-DDT${types === 'start' ? '00:00' : '23:59'}`,
        ),
      });
    setDateCal(true);
  };

  const confirmDate = () => {
    setDateFixed({ ...dateCustom });
  };

  /* 견적의뢰 리스트 부르기 */
  const { payload, error } = useFetchNoData<{
    data: IWongoItem[];
    total: number;
  }>(
    `wongoItems?page=${paging}&limit=20${
      !siteFilterNow || ['all', 'null'].includes(siteFilterNow)
        ? ''
        : '&siteCode=' + siteFilterNow
    }${
      searchNow && searchNow.val
        ? '&' + searchNow.key + '=' + searchNow.val
        : ''
    }${
      dateFixed.start !== ''
        ? '&start=' +
          moment(dateFixed.start).format('yyyy-MM-DD HH:mm:ss.sss') +
          '&end=' +
          moment(dateFixed.end).format('yyyy-MM-DD HH:mm:ss.sss')
        : ''
    }`,
    String(reload) + dateFixed,
  );

  const [wongoList, setWongoList] = useState<IWongoItem[]>([]);

  useEffect(() => {
    if (payload) {
      setWongoList(
        payload.data.filter(
          (code) =>
            siteFilterNow === 'all' ||
            changeSiteCode(code.siteCode).code === siteFilterNow ||
            (siteFilterNow === 'null' && !code.siteCode),
        ),
      );
    } else {
      setWongoList([]);
    }
  }, [payload, siteFilterNow]);

  /* 페이지 */

  const pageSize = 10; //최대 페이지

  const item = payload?.data;

  const totalPage = Math.ceil(payload ? payload.total / 20 : 0);

  /* 답변 모달 */

  const [modalNow, setModalNow] = useState<{ [key: string]: boolean }>({
    img: false,
    txt: false,
    man: false,
  });

  const [modalId, setModalId] = useState<string>();
  const handleModal = async (key: string, bool: boolean, id?: string) => {
    setModalNow((prev) => {
      let close = {
        img: false,
        txt: false,
        man: false,
      };
      return {
        ...close,
        [key]: bool,
      };
    });
    setModalId(id);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const closeModal = () => {
    setModalNow({
      img: false,
      txt: false,
      man: false,
    });
    setModalId(undefined);
    setReload(!reload);
  };
  const changeManager = async (nameNow: string, idNow?: string) => {
    let data = wongoList.find((a) => a._id === idNow);
    if (data) {
      data.wongoManager = nameNow;
      await saveNow(data);
    }
  };
  const wongoStatusChanger = (val?: string): string => {
    switch (val) {
      case '수정요청':
        return 'request';
      case '고객확인요청':
        return 'check';
      case '인쇄요청':
        return 'print';
      default:
        return 'defalut';
    }
  };
  const { payload: userPayload } = useFetchNoData<Tuser[]>(`user`, true);
  const [wongos, setWongos] = useState<Tuser[]>();
  useEffect(() => {
    if (userPayload) {
      let result = userPayload?.filter(
        (a) => a.occupation && a.occupation === 'wongoManager',
      );
      setWongos(result);
    }
  }, [userPayload]);
  const [wongoTemp, setWongoTemp] = useState<string>(
    wongos && wongos.length > 0 ? wongos[0].userId : '',
  );

  const handleManager = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setWongoTemp(value);
  };
  const changeOther = (key: string, bool: boolean, id?: string) => {
    handleModal(key, bool, id);
    let data = wongoList.find((a) => a._id === id);
    if (data) {
      setWongoTemp(
        data.wongoManager ? data.wongoManager : wongos ? wongos[0].userId : '',
      );
    }
  };
  const confirmOther = async (data: IWongoItem) => {
    await saveNow({ ...data, wongoManager: wongoTemp }).then(() => {
      closeModal();
      setReload(!reload);
    });
  };
  const saveNow = async (data: IWongoItem) => {
    const { _id, ...dataWithoutId } = data; // _id를 제외한 새로운 객체 생성
    const requestOptions: AxiosRequestConfig = {
      url: `${SERVER_URL}/wongoItems/${_id}`,
      method: 'put',
      data: dataWithoutId,
    };
    try {
      await axios(requestOptions);
      setReload(!reload);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className="page-title">
        <div className="title_left board_title ">
          <h3 className="col-6">
            <i className="fa fa-cubes" /> 편집의뢰 관리
          </h3>
          <div className="col-6 board_search">
            <div className="col-6 board_id_search">
              <div className="board_id_search_input">
                <select
                  name="key"
                  value={search.key}
                  onChange={(e) => searchHandle(e)}
                >
                  <option value="uniqueName">고객ID</option>
                  <option value="orderNumber">주문번호</option>
                  <option value="fullName">주문자명</option>
                  <option value="title">인쇄물제목</option>
                  <option value="wongoManager">담당자</option>
                </select>
                <input
                  type="text"
                  name="val"
                  value={search.val}
                  onChange={(e) => searchHandle(e)}
                />
                <div
                  className="board_id_search_btn board_search_btn"
                  onClick={searchClick}
                >
                  검색
                </div>
              </div>
            </div>
            <div className="col-6 board_search board_date_search">
              <div className="board_date_search_wrap">
                <h5>날짜 검색</h5>
                <div className="date_search_picker date_start_picker">
                  <DatePicker
                    selected={
                      dateCal === false ? undefined : new Date(dateCustom.start)
                    }
                    onChange={(date: Date | null) => {
                      ChangeDate('start', date);
                    }}
                    fixedHeight
                    selectsStart
                    locale={ko}
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
                <span className="date_search_wave">~</span>

                <div className="date_search_picker date_end_picker">
                  <DatePicker
                    selected={
                      dateCal === false ? undefined : new Date(dateCustom.end)
                    }
                    onChange={(date: Date | null) => {
                      ChangeDate('end', date);
                    }}
                    fixedHeight
                    selectsEnd
                    locale={ko}
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
                <div className="board_search_btn" onClick={() => confirmDate()}>
                  검색
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" />

      <div className="col-12">
        <div className="contents-wrap x_panel mb-2 boardfilter_wrap">
          <div className="groupFilter col-12">
            <h5>사이트필터</h5>
            <div className="groupFilter-wrap orange">
              <div className="groupFilter-inner">
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'all' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('all')}
                >
                  전체
                </div>
                {siteList.map((code, idx) => {
                  let thisData = changeSiteCode(code.sellerNameEN);
                  return (
                    <div
                      key={idx}
                      className={`groupFilter-item ${
                        siteFilterNow == thisData.code ? 'active' : ''
                      }`}
                      onClick={() => changeSiteFilter(thisData.code)}
                    >
                      {thisData.name}
                    </div>
                  );
                })}
                {/* <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'IM' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('IM')}
                >
                  아이엠 디자인
                </div>
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'SP' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('SP')}
                >
                  스피드칼라
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="contents-wrap x_panel">
          <div className="x_content setboard-table-wrap">
            <div className="x_content site-table-wrap">
              {wongoList && (
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered GW-cont-head wongoListTable">
                    <thead>
                      <tr>
                        <th className="cont-w50">번호</th>
                        <th className="cont-w150">주문날짜 / 주문번호</th>
                        <th className="cont-w95">주문자명 / 사이트</th>
                        <th className="cont-w300">제품분류 / 인쇄물제목 </th>
                        <th className="cont-w95">입금상태</th>
                        <th className="cont-w95">진행상태</th>
                        <th className="cont-w200">담당자명</th>
                        <th className="wongoListBtns">관리자메뉴</th>
                        <th className="cont-w150">선/미수금</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wongoList.length > 0 ? (
                        wongoList.map((code, idx) => {
                          let siteInfo = changeSiteCode(code.siteCode);
                          let wongoFind = wongos?.find(
                            (c) => c.userName === code.wongoManager,
                          );
                          let wongoPhone = wongoFind
                            ? (wongoFind.phoneAreaCode
                                ? wongoFind.phoneAreaCode + '-'
                                : '') +
                              (wongoFind.phonePrefix
                                ? wongoFind.phonePrefix + '-'
                                : '') +
                              (wongoFind.phoneSuffix
                                ? wongoFind.phoneSuffix
                                : '')
                            : undefined;
                          return (
                            <tr
                              key={idx}
                              //onClick={() => EditBoard}
                              className="answertr"
                            >
                              <td>{idx + 1}</td>
                              <td>
                                <a href={String(siteInfo.link)} target="blink">
                                  {code.orderNumber}
                                </a>
                                <div className="wongoDevider" />
                                {moment(code.createdAt).format(
                                  'YYYY/MM/DD  HH:mm',
                                )}
                              </td>
                              <td>
                                {code.fullName}
                                <div className="wongoDevider" />
                                {siteInfo.name}
                              </td>
                              <td>
                                <div>
                                  {code.categoryDescription.includes('/')
                                    ? code.categoryDescription.split('/')[1]
                                    : code.categoryDescription}
                                </div>
                                <div className="wongoDevider" />
                                <div className="ellipsisTXT">{code.title}</div>
                              </td>
                              <td>
                                {code.customerInfo && code.customerInfo.isPaid
                                  ? '입금완료'
                                  : '입금전'}
                              </td>
                              <td>
                                <span
                                  className={
                                    'wongoStatus wongoStatus-' +
                                    wongoStatusChanger(code.wongoStatus)
                                  }
                                >
                                  {code.wongoStatus}
                                </span>
                              </td>
                              <td>
                                <div className="position-relative">
                                  {code.wongoManager ? (
                                    <>
                                      <div className="wongoManager-name">
                                        {code.wongoManagerInfo && (
                                          <div className="wongoManager-nameTxt">
                                            {code.wongoManagerInfo
                                              .siteFilter && (
                                              <span
                                                className={
                                                  changeSiteCode(
                                                    code.wongoManagerInfo
                                                      .siteFilter,
                                                  ).code
                                                }
                                              >
                                                {
                                                  changeSiteCode(
                                                    code.wongoManagerInfo
                                                      .siteFilter,
                                                  ).code
                                                }
                                              </span>
                                            )}
                                            {code.wongoManagerInfo.userName}
                                          </div>
                                        )}
                                        {wongoPhone && (
                                          <div className="wongoManager-nameHover">
                                            <i className="fa fa-phone" />
                                            <span> {wongoPhone}</span>
                                          </div>
                                        )}
                                      </div>
                                      <button
                                        className="tableBtn tableBtn-orange btnW-40"
                                        onClick={() =>
                                          isWongoManager
                                            ? changeOther('man', true, code._id)
                                            : alert('원고관리자 메뉴입니다.')
                                        }
                                      >
                                        수정
                                      </button>
                                    </>
                                  ) : (
                                    <button
                                      className="tableBtn tableBtn-orange btnW-100"
                                      onClick={() =>
                                        code._id && isWongoManager && siteFilter
                                          ? changeSiteCode(siteFilter).code ===
                                            code.siteCode
                                            ? changeManager(user, code._id)
                                            : changeOther('man', true, code._id)
                                          : alert('원고관리자 메뉴입니다.')
                                      }
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      />
                                      {' 담당자배정'}
                                    </button>
                                  )}
                                  {modalNow.man && modalId === code._id && (
                                    <div className="wongoOther-window">
                                      <h3>담당자변경</h3>
                                      <select
                                        onChange={(e) => handleManager(e)}
                                        value={wongoTemp}
                                      >
                                        {wongos &&
                                          wongos.map((codeA, idxA) => {
                                            return (
                                              <option
                                                key={idxA}
                                                value={codeA.userId}
                                              >
                                                {(codeA.siteFilter
                                                  ? '<' +
                                                    changeSiteCode(
                                                      codeA.siteFilter,
                                                    ).code +
                                                    '> '
                                                  : '') + codeA.userName}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <div
                                        className="wongoOtherConfirm"
                                        onClick={() => confirmOther(code)}
                                      >
                                        확인
                                      </div>
                                      <div className="clearfix"></div>
                                      {/* {managerIdNow && managerIdNow === code._id && (
                               <input type="text" />
                              )} */}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <button
                                  className="tableBtn tableBtn-green btnW-80"
                                  onClick={() =>
                                    isWongoManager
                                      ? handleModal(
                                          'img',
                                          true,
                                          code.orderNumber,
                                        )
                                      : alert('원고관리자 메뉴입니다.')
                                  }
                                >
                                  시안업로드
                                </button>
                                <button
                                  className="tableBtn tableBtn-skyblue btnW-80"
                                  onClick={() =>
                                    isWongoManager
                                      ? handleModal(
                                          'txt',
                                          true,
                                          code.orderNumber,
                                        )
                                      : alert('원고관리자 메뉴입니다.')
                                  }
                                >
                                  수정요청내용
                                </button>
                              </td>
                              <td>
                                {code.customerInfo && (
                                  <span>
                                    {(code.customerInfo.Balance_Remainder &&
                                    code.customerInfo.Balance_Remainder !== -1
                                      ? code.customerInfo.Balance_Remainder
                                      : 0) -
                                      (code.customerInfo.Balance_Debt &&
                                      code.customerInfo.Balance_Debt !== -1
                                        ? code.customerInfo.Balance_Debt
                                        : 0)}{' '}
                                    원
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="boardNodata">
                            데이터가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="boardPagination">
          {item && item.length > 0 && (
            <BoardPagination
              paging={paging}
              setPaging={setPaging}
              pageSize={pageSize}
              pageLength={totalPage}
            />
          )}
        </div>
      </div>
      <div className="boardModal">
        {modalId && (modalNow.img || modalNow.txt) && (
          <WongoListModal
            type={modalNow.img ? 'img' : 'txt'}
            modalId={modalId}
            closeModal={closeModal}
          />
        )}
      </div>
    </>
  );
}
