import React, { useState, useEffect, useCallback } from 'react';
import useFetch from '@utils/customHook/useFetch';
import Modal from '@components/modal/ModalComp';
import AddWorkModal from '@components/modal/addworkModal';
import UpdateModalAddwork from '@components/modal/addworkComp/update-modal-addwork';
import { SERVER_URL } from '@config/path';
import moment from 'moment';
import Loglist from '@components/loglist';
import { IAddwork, IId, NewAddwork } from '@interfaces/addwork.interface';
import { ProductListType } from '@interfaces/tableCost.interfaces';
import { Site } from '@interfaces/site.interface';
import { AddworkDummy } from '@dummydata/dummy';
import axios from 'axios';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';

interface Props {
  selectedSite: Site;
  sellerNameEN: string;
  GPmodalhandler: (bool: boolean) => void;
  PRmodalhandler: (boolean: boolean) => void;
}
export default function SetAddworkInner({
  selectedSite,
  sellerNameEN,
  GPmodalhandler,
  PRmodalhandler,
}: Props) {
  moment.locale();
  const { loadingNow, loadingHandler } = useLoading();

  const [LISTTYPE, setLISTTYPE] = useState<string>('GP');
  const changeLISTTYPE = (str: string) => {
    setLISTTYPE(str);
    if (str === 'LP') {
      GPmodalhandler(false);
      PRmodalhandler(true);
    } else {
      GPmodalhandler(true);
      PRmodalhandler(false);
    }
  };

  const [productCheckList, setProductCheckList] = useState<IId>();
  const [id, setId] = useState<string>();

  const [newItem, setNewItem] = useState<NewAddwork>({ ...AddworkDummy });
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    check: false,
    log: false,
    sited: false,
  });
  const { payload, loading, error } = useFetch<IAddwork>(
    `addwork/all`,
    loadingNow,
  );
  const [productNames, setProductNames] = useState<ProductListType[]>([]);
  useEffect(() => {
    loadingHandler(true);
    const getProductNames = async () => {
      await axios
        .get(`${SERVER_URL}/productbysite?target=${sellerNameEN}`)
        .then((res) => {
          setProductNames(res.data.data);
        })
        .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    };
    getProductNames();
    loadingHandler(false);
  }, []);
  const [iAddworkList, setIAddworkList] = useState<IAddwork[]>([]);
  const [iAddworkFilter, setIAddworkFilter] = useState<string[]>();
  const [iAddworkFilterNow, setIAddworkFilterNow] = useState<string>('전체');
  useEffect(() => {
    if (payload) {
      let copy = payload.data.filter((a) => a.site === sellerNameEN);
      let filt = copy
        .filter((a) => a.groupCode)
        .map((b) => {
          return b.groupCode;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setIAddworkFilter(filt);
      const filtCopy = changeIAddworkFilter(iAddworkFilterNow, copy);
      nsortBy(nsortNow.type, nsortNow.key, filtCopy);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  const changeIAddworkFilter = (group: string, data: IAddwork[]) => {
    let copy = [...data];
    if (group !== '전체') {
      copy = copy.filter(
        (a) => a.site === sellerNameEN && a.groupCode === group,
      );
      setIAddworkList(copy);
    } else {
      copy = copy.filter((a) => a.site === sellerNameEN);
      setIAddworkList(copy);
    }
    setIAddworkFilterNow(group);
    return copy;
  };
  const setAddWorkId = (target: string, addworkId: string) => {
    let copy = payload && payload.data.filter((a) => a.site === sellerNameEN);
    let productId: string[] = [];
    copy &&
      copy.forEach((e) => e._id === addworkId && (productId = e.productId));
    target === 'log'
      ? setId(addworkId)
      : setProductCheckList({ addworkId, productId });
  };

  const closeModal = (target: string) => {
    loadingHandler(true);
    setModal({ ...modal, [target]: false });
    setNewItem({
      productId: [],
      site: sellerNameEN,
      groupCode: '',
      workType: '',
      workTypeKO: '',
      selecters: [],
      price: [],
      class: '',
    });
    loadingHandler(false);
  };
  const editAddwork = async (id: string) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/addwork/${id}`)
      .then((res) => {
        setNewItem(res.data.data);
        setModal({ ...modal, sited: true });
      })
      .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    loadingHandler(false);
  };

  const delAddwork = async (id: string) => {
    if (window.confirm(CONFIRM.DEL)) {
      loadingHandler(true);
      await axios
        .delete(`${SERVER_URL}/addwork/${id}`)
        .then((res) => {
          res.status === 204
            ? window.alert(MESSAGE.DELDONE)
            : window.alert(MESSAGE.CONFIRMAUTH);
        })
        .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
      loadingHandler(false);
    }
  };
  /**
   * 사이트에서 후가공 기준가격 업데이트하는 함수
   * @param productNameEN
   */
  const [UPModal, setUPModal] = useState<boolean>(false);
  const changeUPModal = (bool: boolean) => {
    setUPModal(bool);
  };

  const [updateInputs, setUdpateInputs] = useState<NewAddwork>();
  const UpdateEvent = async (id?: string, orignId?: string) => {
    loadingHandler(true);
    confirm(CONFIRM.UPDATE) &&
      id &&
      orignId &&
      (await axios
        .get(`${SERVER_URL}/addwork/${id}`)
        .then((res) => {
          setNewItem(res.data.data);
          axios
            .get(`${SERVER_URL}/addwork/${orignId}`)
            .then((response) => {
              setUdpateInputs(response.data.data);
              changeUPModal(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        }));
    loadingHandler(false);
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'createdAt',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (type: boolean, key: string, data: IAddwork[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    copy.sort((a, b) =>
      !type
        ? String(a[key]) < String(b[key])
          ? -1
          : 1
        : String(a[key]) > String(b[key])
        ? -1
        : 1,
    );
    setNsortNow({ ...nsortNow, type: type, key: key });
    setIAddworkList(copy);
  };

  return (
    <>
      <div className="fixedContainer bg-f7f7f7">
        <div className="page-title">
          <div className="title_left">
            <h3>
              <i className="fa fa-cubes" />
              {selectedSite.sellerName} 후가공 <small>후가공기준</small>
              <div className="pull-right">
                <div className="perKind-wrap me-3">
                  <div className="newoption-radio">
                    <input
                      type="radio"
                      name="perKind"
                      id="perAddwork"
                      className="boxInputs"
                      checked={LISTTYPE == 'GP'}
                      onChange={() => changeLISTTYPE('GP')}
                    />
                    <label htmlFor="perAddwork">후가공 기준</label>
                  </div>
                  <div className="newoption-radio">
                    <input
                      type="radio"
                      name="perKind"
                      id="perProduct"
                      className="boxInputs"
                      checked={LISTTYPE == 'LP'}
                      onChange={() => changeLISTTYPE('LP')}
                    />
                    <label htmlFor="perProduct">상품 기준</label>
                  </div>
                </div>
                <button
                  className="goBackBtn"
                  onClick={() => GPmodalhandler(false)}
                >
                  이전으로
                </button>
              </div>
            </h3>
          </div>
        </div>
        <div className="clearfix"></div>
        {payload && (
          <div className="row">
            <div className="col-12">
              <div className="contents-wrap x_panel mb-2">
                <div className="groupFilter">
                  <h5>후가공그룹</h5>
                  <div className="groupFilter-wrap">
                    <div className="groupFilter-inner">
                      <div
                        className={`groupFilter-item ${
                          iAddworkFilterNow == '전체' ? 'active' : ''
                        }`}
                        onClick={() =>
                          changeIAddworkFilter('전체', payload.data)
                        }
                      >
                        전체
                      </div>
                      {iAddworkFilter?.map((filt) => {
                        return (
                          <div
                            key={filt}
                            className={`groupFilter-item ${
                              iAddworkFilterNow == filt ? 'active' : ''
                            }`}
                            onClick={() =>
                              changeIAddworkFilter(filt, payload.data)
                            }
                          >
                            {filt}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="contents-wrap x_panel setETC-table-wrap table-wrap-green">
                {productNames.length > 0 && payload && (
                  <div className="react-bootstrap-table">
                    <table
                      id="addwork-table"
                      className="table table-hover table-bordered"
                    >
                      <thead>
                        <tr>
                          <th
                            className={`sortable ${
                              sortColor === 'groupCode' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(!sortselect, 'groupCode', iAddworkList);
                            }}
                          >
                            후가공그룹{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th
                            className={`sortable ${
                              sortColor === 'workTypeKO' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(!sortselect, 'workTypeKO', iAddworkList);
                            }}
                          >
                            후가공명{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th className="connectedProduct">적용상품수</th>
                          <th
                            className={`sortable cont-w100 ${
                              sortColor === 'createdAt' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(!sortselect, 'createdAt', iAddworkList);
                            }}
                          >
                            생성/수정{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {iAddworkList.map((code, idx) => {
                          let time = moment(code.createdAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          let time2 = moment(code.modifiedAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          return (
                            <tr key={idx}>
                              <td>{code.groupCode}</td>
                              <td>{code.workTypeKO} </td>
                              <td>
                                {code.productId.length != 0 ? (
                                  <>
                                    <div className="productNameMatch-wrap  d-inline-block">
                                      <div className="productNameMatch">
                                        {code.productId.map((ID, idx2) => {
                                          let find = productNames.find(
                                            (a) => a._id === ID,
                                          );
                                          let match = find
                                            ? find.productNameKO
                                            : null;
                                          return (
                                            <React.Fragment key={idx2}>
                                              {idx2 !== 0 ? ', ' : ''}
                                              <span
                                                className={`${
                                                  match === null ? 'red' : ''
                                                }`}
                                              >
                                                {match === null ? (
                                                  <>
                                                    <i className="fa fa-times red"></i>
                                                    {' null'}
                                                  </>
                                                ) : (
                                                  match
                                                )}
                                              </span>
                                            </React.Fragment>
                                          );
                                        })}
                                      </div>

                                      <div className="productNameMatch-hover">
                                        {code.productId.map((ID, idx) => {
                                          let match = productNames.find(
                                            (a) => a._id == ID,
                                          )?.productNameKO;
                                          return <span key={idx}>{match}</span>;
                                        })}
                                        <h5 className="totalProductLength">
                                          - 총{' '}
                                          <span>{code.productId.length}</span>개
                                          상품
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="productNameMatch-wrap">
                                    <i className="fa fa-times red"></i> 적용상품
                                    없음
                                  </div>
                                )}
                              </td>
                              <td>
                                <div className="editDate-wrap">
                                  <div className="editDate-btn">보기</div>
                                  <div className="editDate-view">
                                    <div className="editDate-line">
                                      <h5>생성:</h5>
                                      <p className="standardproductID">
                                        {code.createdBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time}
                                      </p>
                                    </div>
                                    <div className="editDate-line">
                                      <h5>수정:</h5>
                                      <p className="standardproductID">
                                        {code.modifiedBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time2}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button
                                  className="tableBtn tableBtn-orange btnW-90 setpriceBtn"
                                  onClick={() => editAddwork(code._id)}
                                >
                                  <span className="mobileSpan">
                                    <i
                                      className="fa fa-won"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="deskSpan">후가공 수정</span>
                                </button>
                                <button className="tableBtn tableBtn-gray btnW-80 setpriceBtn">
                                  <span className="mobileSpan">
                                    <i
                                      className="fa fa-file-text-o"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="deskSpan">
                                    업데이트 이력
                                  </span>
                                </button>
                                <button
                                  className="tableBtn tableBtn-red btnW-70 setPriceDelEvents setpriceBtn"
                                  onClick={() => delAddwork(code._id)}
                                >
                                  <span className="mobileSpan">
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="deskSpan">삭제하기</span>
                                </button>
                                <button
                                  className="tableBtn tableBtn-purple setpriceBtn"
                                  onClick={() =>
                                    UpdateEvent(code._id, code.originId)
                                  }
                                >
                                  <span className="mobileSpan">
                                    <i
                                      className="fa fa-mixcloud"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="deskSpan">
                                    후가공 업데이트
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {modal.log && (
                  <Modal close={closeModal} title={'로그 리스트'} id={'log'}>
                    <Loglist id={id} category="addwork" />
                  </Modal>
                )}
                {modal.sited && (
                  <>
                    <Modal
                      close={closeModal}
                      title={'후가공 수정'}
                      id={'sited'}
                    >
                      <AddWorkModal
                        AddworkType={sellerNameEN}
                        newItem={newItem}
                        setNewItem={setNewItem}
                        closeModal={closeModal}
                      />
                    </Modal>
                  </>
                )}
                {updateInputs && UPModal && (
                  <UpdateModalAddwork
                    selectedSite={sellerNameEN}
                    newItem={newItem}
                    setNewItem={setNewItem}
                    updateItem={updateInputs}
                    changeUPModal={changeUPModal}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
