import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import axios from 'axios';
import {
  ProductListType,
  PopUp,
  Notification,
} from '@interfaces/tableCost.interfaces';
import { useLoading } from '@config/loadingContext';
import useFetch from '@utils/customHook/useFetch';
import moment from 'moment';

interface Props {
  closeLinkModal: () => void;
  popupInputs: PopUp;
  setpopupInputs: Dispatch<SetStateAction<PopUp>>;
}

export default function PopupLinkModal({
  closeLinkModal,
  popupInputs,
  setpopupInputs,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();

  const { payload, error } = useFetch<Notification>(`notification`, loadingNow);

  /** 가져올 링크 리스트 */

  const applySite = popupInputs.applySite;

  const [linkArr, setLinkArr] = useState<Notification[]>([]);

  useEffect(() => {
    if (payload && payload.data && applySite) {
      const matchSite = payload.data.filter((item) => {
        return (
          item.appliedSites &&
          applySite.every((a) => item.appliedSites.includes(a))
        );
      });
      setLinkArr(matchSite);
    }
  }, [payload, applySite]);

  const linkClick = (url: string) => {
    const copyInput = { ...popupInputs, linkUrl: url };
    setpopupInputs(copyInput);
  };

  return (
    <>
      <div id="linkpop-wrap" className="optionchoose-wrap">
        <div className="linkpop-inner">
          <div className="linkpop-cont">
            <h3>팝업용 공지사항 링크</h3>
          </div>
          {linkArr && applySite.length > 0 ? (
            <div className="linkpop-list scroll">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>공지 제목</th>
                    <th>생성일</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {linkArr
                  .sort(
                    (a, b) =>
                      moment(b.createdAt).valueOf() -
                      moment(a.createdAt).valueOf(),
                  )
                  .map((a, i) => {
                    let createdTime = moment(a.createdAt).format(
                      'YYYY-MM-DD (HH:mm)',
                    );
                    return (
                      <>
                        <tbody key={i}>
                          <tr>
                            <td>{a.title}</td>
                            <th>{createdTime}</th>
                            <td>
                              <input
                                type="button"
                                onClick={() =>
                                  linkClick(
                                    '/customer/notice?noticeId=' + a._id,
                                  )
                                }
                                value={'링크'}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
              </table>
            </div>
          ) : (
            <>
              <div className="noLink_pop">적용 사이트를 선택해 주세요.</div>
            </>
          )}
          <div
            className="yBtn yBtn-xs gray me-2 d-inline-block"
            data-toggle="tooltip"
            onClick={() => {
              closeLinkModal();
            }}
          >
            <span>
              <i className="fa fa-check" aria-hidden="true"></i> 닫기
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
