import { useState, useEffect } from 'react';
import axios from 'axios';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import { FirstInput, ProductListType } from '@interfaces/tableCost.interfaces';
import { Site } from '@interfaces/site.interface';
import { useAuthState } from '@config/context';
import { basicConst } from '@dummydata/dummy';
import TodayModal from '@components/modal/productmanager/today-modal-inner';
import TodayCalcModal from '@components/modal/productmanager/todayCalc-modal-inner';
import WongoPriceModal from '@components/modal/productmanager/wongoPrice-modal-inner';
import NoticeModal from '@components/modal/productmanager/notice-modal-inner';
import BundleModal from '@components/modal/productmanager/bundle-modal-inner';
import PreviewModal from '@components/modal/previewModal';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import ModalEmpty from '@components/modal/ModalEmpty';
import DeleteModal from '@components/modal/deleteModal';

interface Props {
  selectedSite: Site;
  sellerNameEN: string;
  SEmodalhandler: (boolean: boolean) => void;
}
export default function SetETCInner({
  selectedSite,
  sellerNameEN,
  SEmodalhandler,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, loading, error } = useFetch<ProductListType>(
    `productbysite?target=${sellerNameEN}`,
    String(loadingNow),
  );
  /**S:제품필터 */
  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>();
  const [typeFilterNow, setTypeFilterNow] = useState<string>('전체');
  const [productFilter, setProductFilter] = useState<string[]>();
  const [productFilterNow, setProductFilterNow] = useState<string>('전체');
  useEffect(() => {
    if (payload) {
      let copy = [...payload.data];
      setProductList(copy);
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
      let typefilt = copy
        .filter((a) => a.typeOfPriceDetermintion)
        .map((b) => {
          return b.typeOfPriceDetermintion;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setTypeFilter(typefilt);
      let dataA = changeProductFilter('type', typeFilterNow, copy);
      changeProductFilter('group', productFilterNow, dataA);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  const changeProductFilter = (
    kind: string,
    value: string,
    data: ProductListType[],
  ) => {
    let typeNow = kind == 'type' ? value : typeFilterNow;
    let groupNow = kind == 'group' ? value : productFilterNow;
    let copy = [...data];
    if (kind === 'type') {
      copy = copy.filter((a) =>
        typeNow !== '전체' ? a.typeOfPriceDetermintion === typeNow : a,
      );
      setProductList(copy);
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
      setTypeFilterNow(typeNow);
      setProductFilterNow('전체');
    } else {
      copy = copy
        .filter((a) =>
          groupNow !== '전체' ? a.category[0].categoryName1st == groupNow : a,
        )
        .filter((a) =>
          typeNow !== '전체' ? a.typeOfPriceDetermintion === typeNow : a,
        );
      setProductList(copy);
      setProductFilterNow(groupNow);
    }
    return copy;
  };
  /**E:제품필터 */
  const siteName = selectedSite.sellerName;
  let basicCopy = JSON.parse(JSON.stringify(basicConst));
  const [firstInputs, setFirstInputs] = useState<FirstInput>({
    ...basicCopy,
  });
  /** 모달 state*/
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    today: false,
    wongo: false,
    notice: false,
    bundle: false,
    delete: false,
  });
  const openModal = (TYPE: string, TARGET: string, PRODUCT: string) => {
    loadingHandler(true);
    GetSelected(TYPE, TARGET, PRODUCT);
  };
  const closeModal = () => {
    setModal({
      today: false,
      wongo: false,
      notice: false,
      bundle: false,
      delete: false,
    });
  };
  /**미리보기모달 state*/
  const [previewName, setPreviewName] = useState<string>('');
  const [preview, setPreview] = useState<boolean>(false); //프리뷰
  const previewhandler = (boolean: boolean) => setPreview(boolean);
  /**
   * 기준 상품 프리뷰 오픈
   * @param productNameEN
   */
  const PreviewEvent = (productID: string) => {
    setPreviewName(productID);
    loadingHandler(true);
    previewhandler(true);
  };
  const GetSelected = async (TYPE: string, TARGET: string, PRODUCT: string) => {
    await axios
      .get(`${SERVER_URL}/productbysite?target=${TARGET}&product=${PRODUCT}`)
      .then((res) => {
        setFirstInputs(res.data.data[0]); //데이터 업데이트
        setPreviewName(PRODUCT);
        setModal({ ...modal, [TYPE]: true });
        loadingHandler(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * 사이트에서 제품 삭제하는 함수
   * @param productNameEN
   */
  const DelEvent = async (idNow: string) => {
    loadingHandler(true);
    confirm(CONFIRM.DEL)
      ? await axios
          .delete(`${SERVER_URL}/productbysite/${idNow}`)
          .then((res) => {
            alert(MESSAGE.DELDONE);
          })
          .catch((err) => {
            alert(MESSAGE.CONFIRMAUTH);
            console.log(err);
          })
      : '';
    loadingHandler(false);
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'createdAt',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (type: boolean, key: string, data: ProductListType[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    if (key !== 'categoryName1st') {
      copy.sort((a, b) =>
        !type
          ? String(a[key]) < String(b[key])
            ? -1
            : 1
          : String(a[key]) > String(b[key])
          ? -1
          : 1,
      );
    } else {
      copy.sort((a, b) =>
        !type
          ? a.category[0].categoryName1st < b.category[0].categoryName1st
            ? -1
            : 1
          : a.category[0].categoryName1st > b.category[0].categoryName1st
          ? -1
          : 1,
      );
    }
    setProductList(copy);
    setNsortNow({ ...nsortNow, type: type, key: key });
  };

  /** 딜리트 모달 */

  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };

  const [delID, setDelID] = useState<string>();
  const openDelConfirm = (id: string) => {
    setDelID(id);
    changeModal('delete', true);
  };

  return (
    <>
      <div className="fixedContainer bg-f7f7f7">
        <div className="page-title">
          <div className="title_left">
            <h3>
              <i className="fa fa-cubes" />
              {selectedSite.sellerName} 판매 방식 결정하기
              <small>상품관리</small>
              <div className="pull-right">
                <button
                  className="goBackBtn"
                  onClick={() => SEmodalhandler(false)}
                >
                  이전으로
                </button>
              </div>
            </h3>
          </div>
        </div>
        <div className="clearfix"></div>

        {payload && (
          <div className="row">
            <div className="col-12">
              <div className="contents-wrap x_panel mb-2">
                <div className="groupFilter">
                  <h5>가격표타입</h5>
                  <div className="groupFilter-wrap">
                    <div className="groupFilter-inner">
                      <div
                        className={`groupFilter-item ${
                          typeFilterNow == '전체' ? 'active' : ''
                        }`}
                        onClick={() =>
                          changeProductFilter('type', '전체', payload.data)
                        }
                      >
                        전체
                      </div>
                      {typeFilter?.map((filt) => {
                        return (
                          <div
                            key={filt}
                            className={`groupFilter-item ${
                              typeFilterNow == filt ? 'active' : ''
                            }`}
                            onClick={() =>
                              changeProductFilter('type', filt, payload.data)
                            }
                          >
                            {filt == 'priceComplete' ? '완성형' : '계산형'}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="groupFilter">
                  <h5>제품그룹</h5>
                  <div className="groupFilter-wrap purple">
                    <div className="groupFilter-inner">
                      <div
                        className={`groupFilter-item ${
                          productFilterNow == '전체' ? 'active' : ''
                        }`}
                        onClick={() =>
                          changeProductFilter('group', '전체', payload.data)
                        }
                      >
                        전체
                      </div>
                      {productFilter?.map((filt) => {
                        return (
                          <div
                            key={filt}
                            className={`groupFilter-item ${
                              productFilterNow == filt ? 'active' : ''
                            }`}
                            onClick={() =>
                              changeProductFilter('group', filt, payload.data)
                            }
                          >
                            {filt}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="contents-wrap x_panel">
                <div className="x_content setETC-table-wrap setting">
                  {productList && (
                    <div className="react-bootstrap-table">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th
                              className="sortable"
                              onClick={() => {
                                nsortBy(
                                  !sortselect,
                                  'categoryName1st',
                                  productList,
                                );
                              }}
                            >
                              분류 <span className="order-4"></span>
                            </th>
                            <th
                              className="sortable"
                              onClick={() => {
                                nsortBy(
                                  !sortselect,
                                  'productNameKO',
                                  productList,
                                );
                              }}
                            >
                              제품명 <span className="order-4"></span>
                            </th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.map((code, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  {code.category[0].categoryName1st +
                                    '/' +
                                    code.category[0].categoryName2nd}
                                </td>
                                <td>
                                  {code.productNameKO +
                                    ' (' +
                                    code.productNameEN +
                                    ')'}
                                </td>
                                <td>
                                  <button
                                    className={`tableBtn 
                                      tableBtn-purple
                                  btnW-80 setpriceBtn`}
                                    onClick={() =>
                                      openModal(
                                        'today',
                                        sellerNameEN,
                                        code.productNameEN,
                                      )
                                    }
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-file-text-o"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">
                                      당일판 설정
                                    </span>
                                  </button>
                                  <button
                                    className="tableBtn tableBtn-green btnW-90 setpriceBtn"
                                    onClick={() =>
                                      openModal(
                                        'wongo',
                                        sellerNameEN,
                                        code.productNameEN,
                                      )
                                    }
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">
                                      편집원고 설정
                                    </span>
                                  </button>
                                  <button
                                    className="tableBtn tableBtn-orange btnW-90 setpriceBtn"
                                    onClick={() =>
                                      openModal(
                                        'notice',
                                        sellerNameEN,
                                        code.productNameEN,
                                      )
                                    }
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-won"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">
                                      주의사항 설정
                                    </span>
                                  </button>
                                  {/* <button
                                  className="tableBtn tableBtn-green btnW-90 setpriceBtn"
                                  onClick={() =>
                                    openModal(
                                      'bundle',
                                      sellerNameEN,
                                      code.productNameEN,
                                    )
                                  }
                                >
                                  <span className="mobileSpan">
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="deskSpan">
                                    묶음배송 설정
                                  </span>
                                </button> */}
                                  {/* <button
                                  className="tableBtn tableBtn-skyblue btnW-70 setPricePreview setpriceBtn"
                                  onClick={() => PreviewEvent(code._id)}
                                >
                                  <span className="mobileSpan">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="deskSpan">미리보기</span>
                                </button> */}
                                  <button
                                    className="tableBtn tableBtn-red btnW-70 setPriceDelEvents setpriceBtn"
                                    onClick={() => openDelConfirm(code._id)}
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">삭제하기</span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {modal.today &&
        (firstInputs.typeOfPriceDetermintion === 'priceComplete' ? (
          <ModalEmpty close={closeModal} id={'today'}>
            <TodayModal
              siteName={siteName}
              firstInputs={firstInputs}
              setFirstInputs={setFirstInputs}
              closeModal={closeModal}
            />
          </ModalEmpty>
        ) : (
          <ModalEmpty close={closeModal} id={'today'}>
            <TodayCalcModal
              siteName={siteName}
              firstInputs={firstInputs}
              setFirstInputs={setFirstInputs}
              closeModal={closeModal}
            />
          </ModalEmpty>
        ))}
      {modal.wongo && (
        <>
          <WongoPriceModal
            siteName={siteName}
            firstInputs={firstInputs}
            closeModal={closeModal}
          />
        </>
      )}
      {modal.notice && (
        <>
          <ModalEmpty close={closeModal} id={'notice'}>
            <NoticeModal
              siteName={siteName}
              firstInputs={firstInputs}
              setFirstInputs={setFirstInputs}
              closeModal={closeModal}
            />
          </ModalEmpty>
        </>
      )}
      {modal.bundle && (
        <>
          <BundleModal
            siteName={siteName}
            firstInputs={firstInputs}
            setFirstInputs={setFirstInputs}
            closeModal={closeModal}
          />
        </>
      )}
      {modal.delete && delID && (
        <DeleteModal
          delID={delID}
          delEvents={DelEvent}
          changeModal={changeModal}
        />
      )}
      {preview && (
        <PreviewModal
          from={sellerNameEN}
          previewProduct={previewName}
          previewhandler={previewhandler}
        />
      )}
    </>
  );
}
