import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuthState, useAuthDispatch } from '@config/reducer.index';
import { Pages } from '@interfaces/globals.types';
import { navMenuElement } from '@config/route';
import { useSidbarContext } from '@config/context';
import { changeUserDegree, logout } from '@config/actions';
import Timer from '@components/timer';
import { getCookie } from '@config/cookies';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import {
  boardPayload,
  estimatePayload,
  IWongoItem,
} from '@interfaces/tableCost.interfaces';
interface IProps {
  loginhandler: (login: boolean) => void;
}

function Sidebar({ loginhandler }: IProps) {
  // const [navOpen, setNavOpen] = useState<Pages>('siteList');
  const location = useLocation();
  const history = useHistory();
  const { navOpen, setNavOpen } = useSidbarContext();
  const handleSetNav = (ele: Pages) => setNavOpen(ele);
  const { user, userName, userClass, occupation } = useAuthState();
  const dispatch = useAuthDispatch();

  const logoutHandler = async () => {
    loginhandler(false);
    await logout(dispatch);
    history.replace('/login');
  };

  const present = new Date(); //현재
  const [reload, setReload] = useState<boolean>(false);
  /* 보드 리스트 부르기 */
  const { payload: boardData } = useFetchNoData<boardPayload>(
    `board/all?page=1&limit=100`,
    reload,
  );
  const boardNew: number = boardData
    ? boardData.board.filter((a) => !a.boardAnswer).length
    : 0;
  const { payload: estiData } = useFetchNoData<estimatePayload>(
    `estimate-req?page=1&limit=100`,
    reload,
  );
  const estiNew: number = estiData
    ? estiData.data.filter((a) => a.estimateType === '').length
    : 0;
  const { payload: wongoData } = useFetchNoData<{ data: IWongoItem[] }>(
    `wongoItems`,
    reload,
  );
  const wongoNew: number = wongoData
    ? wongoData.data.filter((a) => a.wongoStatus === '작업전').length
    : 0;
  return (
    <>
      <div className="col-md-3 left_col menu_fixed">
        <div className="left_col scroll-view">
          <div className="navbar nav_title">
            <Link className="navbar-brand" to="/home">
              <img
                className="md-logo img-fluid"
                src="/pr_logo_g.png"
                alt="로고이미지"
              />
              <img
                className="sm-logo img-fluid"
                src="/pr_logo_gs.png"
                alt="로고이미지 스몰"
              />
            </Link>
          </div>

          <div className="clearfix"></div>
          <div className="profile clearfix">
            <div className="profile_pic">
              <img
                src="/pr_logo_s.png"
                alt="..."
                className="img-circle profile_img"
              />
            </div>
            <div className="profile_info">
              <span>{changeUserDegree(userClass, occupation)}</span>
              <h2>{userName}</h2>
            </div>
          </div>
          <div
            id="sidebar-menu"
            className="main_menu_side hidden-print main_menu"
          >
            <div className="menu_section">
              <ul className="nav side-menu mb-4">
                {navMenuElement
                  .filter(
                    (a) =>
                      !a.prohibit.includes(userClass) &&
                      !a.bannedId.includes(user),
                  )
                  .map((e, i) => {
                    let allTypes =
                      e.subMenu &&
                      e.subMenu
                        .map((e) =>
                          e.subMenu ? e.subMenu.map((e) => e.type) : e.type,
                        )
                        .flat(2);

                    return (
                      <li
                        key={e.topMenuNameKO + i}
                        className={
                          allTypes && allTypes.includes(navOpen) ? 'active' : ''
                        }
                      >
                        {/* <Link
                        to={
                          e.subMenu ? e.subMenu[0].path : e.path ? e.path : ''
                        }
                      > */}
                        <button
                          onClick={() =>
                            handleSetNav(e.subMenu ? e.subMenu[0].type : '')
                          }
                        >
                          <i className={`fa ${e.icon}`} />
                          {e.topMenuNameKO}
                          {e.subMenu && <span className="fa fa-chevron-down" />}
                          {e.topMenuNameKO === '판매 관리' &&
                            estiNew + boardNew + wongoNew > 0 && (
                              <span className="navBadge">N</span>
                            )}
                        </button>
                        {/* </Link> */}
                        <ul className="nav child_menu">
                          {e.subMenu &&
                            e.subMenu
                              .filter(
                                (a) =>
                                  !a.prohibit.includes(userClass) &&
                                  !a.bannedId.includes(user),
                              )
                              .map((e, i) => {
                                let subPath = e.subMenu?.map((e) => e.path);

                                return (
                                  <li
                                    key={e.menuName + i}
                                    className={
                                      subPath?.includes(location.pathname) ||
                                      e.path === location.pathname
                                        ? 'active'
                                        : ''
                                    }
                                  >
                                    {!e.subMenu ? (
                                      <Link
                                        to={e.path}
                                        onClick={() => handleSetNav(e.type)}
                                      >
                                        {e.menuName}
                                        {e.menuName === '게시판 관리' &&
                                          boardNew > 0 && (
                                            <span className="navBadge">
                                              {boardNew}
                                            </span>
                                          )}
                                        {e.menuName === '견적의뢰 관리' &&
                                          estiNew > 0 && (
                                            <span className="navBadge">
                                              {estiNew}
                                            </span>
                                          )}

                                        {e.menuName === '편집의뢰 관리' &&
                                          wongoNew > 0 && (
                                            <span className="navBadge">
                                              {wongoNew}
                                            </span>
                                          )}
                                      </Link>
                                    ) : (
                                      <>
                                        <Link
                                          to={e.path}
                                          onClick={() => handleSetNav(e.type)}
                                        >
                                          {e.menuName}
                                        </Link>
                                        <ul className="nav child_sub_menu">
                                          {e.subMenu
                                            .filter(
                                              (a) =>
                                                !a.prohibit.includes(userClass),
                                            )
                                            .map((e, i) => (
                                              <li
                                                key={e.menuName + i}
                                                className={
                                                  e.path === location.pathname
                                                    ? 'active'
                                                    : ''
                                                }
                                              >
                                                <Link
                                                  to={e.path}
                                                  onClick={() =>
                                                    handleSetNav(e.type)
                                                  }
                                                >
                                                  {e.menuName}
                                                </Link>
                                              </li>
                                            ))}
                                        </ul>
                                      </>
                                    )}
                                  </li>
                                );
                              })}
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="log_wrap clearfix">
            <Timer loginhandler={loginhandler} />
            <div
              className="logout_btn text-center"
              onClick={() =>
                confirm('로그 아웃 하시겠습니까?') && logoutHandler()
              }
            >
              <div className="logout_text">
                <p>로그아웃</p>
                <i className="fa fa-power-off"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
