import { useState, useEffect, ChangeEvent } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useLoading } from '@config/loadingContext';
import { IWongoItem, IWongoData } from '@interfaces/tableCost.interfaces';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { NEST_SERVER_URL, SERVER_URL } from '@config/path';

import ModalEmpty from '@components/modal/ModalEmpty';
import BardImgModal from '@components/modal/boardImgModal';
import React from 'react';
import S3ImageLoader from '@components/s3ImageLoader';
import { S3 } from 'aws-sdk';
import { useAuthState } from '@config/context';
import { getOneUser } from '@config/actions';
interface IProps {
  type: string;
  modalId: string;
  closeModal: () => void;
}

export default function WongoListModal({ type, modalId, closeModal }: IProps) {
  const [reload, setReload] = useState<boolean>(false);
  const { payload } = useFetchNoData<{
    data: IWongoItem[];
    total: number;
  }>(`wongoItems?orderNumber=${modalId}`, reload);
  const { user } = useAuthState();
  useEffect(() => {
    if (payload) {
      let dummy = Array(4)
        .fill('')
        .map((a, i) => {
          let findIdx =
            payload.data[0].wongoData &&
            payload.data[0].wongoData.find(
              (a) => a.requestCount !== undefined && a.requestCount === i,
            );
          if (findIdx) {
            return findIdx;
          } else {
            return {
              requestCount: i,
              wongoFile: undefined,
              wongoMemo: undefined,
            };
          }
        });
      setWongoData({
        ...payload.data[0],
        wongoData: [...dummy],
      });
    }
  }, [payload]);

  //이미지 클릭시 이미지 모달
  const [wongoData, setWongoData] = useState<IWongoItem>();
  const getImgUrl = (
    element: {
      url: string;
      key: string;
    },
    idx: number,
  ) => {
    if (wongoData && wongoData.wongoData) {
      let copy = [...wongoData.wongoData];
      copy[idx].wongoFile = {
        path: element.url,
        fileName: element.key.replace('images/wongo/', ''),
      };
      setWongoData((prev) => {
        if (prev) return { ...prev, wongoData: [...copy] };
      });
    }
  };
  const downloadFile = (addr: string, name: string) => {
    fetch(addr, {
      method: 'GET',
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
      })
      .catch((err) => {
        console.error('err: ', err);
      });
  };
  const checkImg = (path: string) => {
    if (path) {
      console.log('path', path);
      let popupWidth = 600; // 팝업 너비
      let popupHeight = 600; // 팝업 높이
      let left = (window.screen.width - popupWidth) / 2; // 화면 중앙 정렬
      let top = (window.screen.height - popupHeight) / 2;
      let newWindow = window.open(
        '',
        '_blank',
        `width=${popupWidth},height=${popupHeight},top=${top},left=${left},resizable=no,scrollbars=no`,
      );
      if (newWindow) {
        newWindow.document.write(`
            <html>
              <head>
                <title>Image Preview</title>
                <style>
                  body {
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f0f0f0;
                    height: 100vh;
                  }
                  img {
                    max-width: 100%;
                    max-height: 100%;
                  }
                </style>
              </head>
              <body>
                <img src="${path}" alt="Image Preview" />
              </body>
            </html>
          `);
        newWindow.document.close();
      }
      // downloadFile(data.wongoFile.path, data.wongoFile.fileName);
    }
  };
  useEffect(() => {
    if (wongoData) {
      let copy =
        wongoData.wongoData && wongoData.wongoData.filter((a) => a.wongoMemo);
      let findIdx = copy ? copy[copy.length - 1].requestCount + 1 : 0;
      changeMemoIdx(findIdx);
    }
  }, [wongoData]);
  const [memoIdx, setMemoIdx] = useState<number>(0);
  const changeMemoIdx = (num: number) => {
    setMemoIdx(memoIdx !== num ? num : 0);
  };

  // const handleMemo = (e: ChangeEvent<HTMLTextAreaElement>) => {
  //   const { name, value } = e.target;
  //   if (wongoData && wongoData.wongoData) {
  //     let copy = [...wongoData.wongoData];
  //     copy[Number(name)].wongoMemo = value;
  //     setWongoData((prev) => {
  //       if (prev) return { ...prev, wongoData: [...copy] };
  //     });
  //   }
  // };
  const saveNow = async () => {
    if (wongoData) {
      const { _id, wongoStatus, ...dataWithoutId } = wongoData; // _id를 제외한 새로운 객체 생성
      const requestOptions: AxiosRequestConfig = {
        url: `${SERVER_URL}/wongoItems/${_id}`,
        method: 'put',
        data: { ...dataWithoutId, wongoStatus: '고객확인요청' },
      };
      try {
        await axios(requestOptions);
        alert('저장 완료');
        setReload(!reload);
        closeModal();
      } catch (e) {
        alert('저장 실패');
        console.log(e);
      }
    } else {
      alert('데이터 이상.');
    }
  };

  return (
    <>
      <div className="modalY-overlay" onClick={() => closeModal()}></div>
      <div id="standardproduct-window" className="preview-window preview-800">
        <div
          className="closeBtn"
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa fa-times"></i>
        </div>
        <div className="bg-f7f7f7 p-4">
          <div className="contents-wrap x_panel">
            <div className="x_title">
              <h4>{type === 'img' ? '시안업로드' : '수정요청내용확인'}</h4>
            </div>
            <div className="clearfix"></div>
            <div className="x_content">
              <div className="form-group">
                {type === 'img' ? (
                  <div className="cost-cont">
                    <table
                      className="wongoList-table wongoList-table-img table yTable rename"
                      cellSpacing="0"
                    >
                      <tbody>
                        {wongoData &&
                          wongoData.wongoData &&
                          wongoData.wongoData.map((code, idx) => {
                            return (
                              <tr key={idx}>
                                <th className="noBorder">
                                  {code.requestCount === 0
                                    ? '최초 시안업로드'
                                    : `수정시안 ${code.requestCount}차`}
                                </th>
                                <td>
                                  <div className="wongoFile-inner">
                                    {code.wongoFile &&
                                    code.wongoFile.fileName &&
                                    code.wongoFile.path ? (
                                      code.wongoFile.fileName
                                    ) : (
                                      <S3ImageLoader
                                        subCategories={'wongo'}
                                        getImgUrl={(val: {
                                          url: string;
                                          key: string;
                                        }) => getImgUrl(val, idx)}
                                      />
                                    )}
                                  </div>
                                  <button
                                    className={`imgConfirm${
                                      code.wongoFile &&
                                      code.wongoFile.fileName &&
                                      code.wongoFile.path
                                        ? ''
                                        : ' disabled'
                                    }`}
                                    onClick={() =>
                                      code.wongoFile &&
                                      code.wongoFile.fileName &&
                                      code.wongoFile.path
                                        ? checkImg(code.wongoFile.path)
                                        : alert('파일을 먼저 업로드 해주세요.')
                                    }
                                  >
                                    업로드 시안확인
                                  </button>
                                  {/* <button
                                  className="imgConfirm"
                                  onClick={() =>
                                    code.wongoFile &&
                                    code.wongoFile.fileName &&
                                    code.wongoFile.path
                                      ? confirmImg(0)
                                      : alert('파일을 먼저 업로드 해주세요.')
                                  }
                                >
                                  시안업로드 완료
                                </button> */}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="cost-cont">
                    <table
                      className="wongoList-table table yTable rename"
                      cellSpacing="0"
                    >
                      <tbody>
                        {wongoData &&
                          wongoData.wongoData &&
                          wongoData.wongoData.map((code, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                <tr>
                                  <th className="noBorder">수정요청 횟수</th>
                                  <td>{idx + 1}차</td>
                                </tr>
                                <tr className="">
                                  <th>내용보기</th>
                                  <td>
                                    {memoIdx !== idx + 1 ? (
                                      <button
                                        className="active full"
                                        onClick={() => changeMemoIdx(idx + 1)}
                                      >
                                        내용열기
                                      </button>
                                    ) : (
                                      <>
                                        <textarea
                                          name={String(idx)}
                                          className="full"
                                          id={'memo' + String(idx + 1)}
                                          // onChange={(e) => handleMemo(e)}
                                          value={code.wongoMemo}
                                          readOnly={true}
                                        ></textarea>
                                        <button
                                          className="full"
                                          onClick={() => changeMemoIdx(0)}
                                        >
                                          닫기
                                        </button>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          {type === 'img' && (
            <div className="row pt-2">
              <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                <div
                  className="price-start-btn btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() => {
                    saveNow();
                  }}
                >
                  <span>
                    <i className="fa fa-check" aria-hidden="true"></i> 수정완료
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
