import { Dispatch, useState, SetStateAction } from 'react';
import {
  NewAddwork,
  NewAddworkSelecters,
  NewAddworkSelect,
} from '@interfaces/addwork.interface';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DragUpdate,
} from 'react-beautiful-dnd';
// import ReorderWindow from './reorder/reorder-window';
interface Props {
  reorderType: string;
  newItem: NewAddwork;
  setNewItem: Dispatch<SetStateAction<NewAddwork>>;
  reOrderIndex: number;
  closeModal: () => void;
}

export default function AddworkReorder({
  reorderType,
  newItem,
  setNewItem,
  reOrderIndex,
  closeModal,
}: Props) {
  const [reOrderColumns, setReOrderColumns] = useState([
    ...newItem.selecters[reOrderIndex].select,
  ]);
  const [reOrderSelect, setReOrderSelect] = useState([...newItem.selecters]);
  const reOrderFinish = () => {
    let temp = { ...newItem };
    if (reorderType == 'option') {
      temp.selecters[reOrderIndex].select = reOrderColumns;
    } else {
      temp.selecters = reOrderSelect;
    }
    setNewItem(temp);
    closeModal();
  };
  const onDragEnd = ({ source, destination }: DragUpdate) => {
    if (destination === undefined || destination === null) return null;
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    if (reorderType == 'option') {
      let newList = reOrderColumns.filter((_, idx) => idx !== source.index);
      newList.splice(destination.index, 0, reOrderColumns[source.index]);
      setReOrderColumns(newList);
    } else {
      let newList = reOrderSelect.filter((_, idx) => idx !== source.index);
      newList.splice(destination.index, 0, reOrderSelect[source.index]);
      setReOrderSelect(newList);
    }
    return null;
  };
  return (
    <>
      <div id="reorder-wrap">
        <div className="reorder-inner">
          <div className="reorder-cont">
            <h3>
              {newItem.selecters[reOrderIndex].title}
              <span> - 순서변경</span>
            </h3>
            {reorderType == 'option' ? (
              <>
                {reOrderColumns && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <div className="ReorderStyledWrap mb-3">
                      <Droppable droppableId="reorderId" key="reorderkey">
                        {(provided) => (
                          <div className="StyledColumn scroll">
                            <ul
                              className="StyledList"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {reOrderColumns.map((a, index) => (
                                <Draggable
                                  key={a.code}
                                  draggableId={a.code}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <li
                                      className={`StyledItem ${
                                        snapshot.isDragging ? 'dragging' : ''
                                      }
                                                            `}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div>{a.title}</div>
                                    </li>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </ul>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </DragDropContext>
                )}
              </>
            ) : (
              <>
                {reOrderSelect && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <div className="ReorderStyledWrap mb-3">
                      <Droppable droppableId="reorderId" key="reorderkey">
                        {(provided) => (
                          <div className="StyledColumn scroll">
                            <ul
                              className="StyledList"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {reOrderSelect.map((a, index) => (
                                <Draggable
                                  key={a.title}
                                  draggableId={a.title}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <li
                                      className={`StyledItem ${
                                        snapshot.isDragging ? 'dragging' : ''
                                      }
                                                            `}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div>
                                        {a.title.replace('-OnlyPrice', '')}
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </ul>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </DragDropContext>
                )}
              </>
            )}
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => closeModal()}
            >
              이전으로
            </div>
            <div
              className="yBtn yBtn-md green text-center d-inline-block"
              onClick={() => reOrderFinish()}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
