import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import axios, { AxiosRequestConfig } from 'axios';
import { FirstInput } from '@interfaces/tableCost.interfaces';
import { IAddwork, IId, NewAddwork } from '@interfaces/addwork.interface';
import { ProductListType, Data } from '@interfaces/tableCost.interfaces';
import { useLoading } from '@config/loadingContext';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { AddworkDummy } from '@dummydata/dummy';
interface Props {
  siteName: string;
  sellerNameEN: string;
  firstInputs: FirstInput;
  changeLPModal: (bool: boolean) => void;
}

export default function SPNameModal({
  siteName,
  sellerNameEN,
  firstInputs,
  changeLPModal,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, loading, error } = useFetch<IAddwork>(
    `addwork/all`,
    loadingNow,
  );
  const [productNames, setProductNames] = useState<ProductListType[]>([]);
  useEffect(() => {
    loadingHandler(true);
    const getProductNames = async () => {
      await axios
        .get(`${SERVER_URL}/productbysite?target=${sellerNameEN}`)
        .then((res) => {
          setProductNames(res.data.data);
        })
        .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    };
    getProductNames();
    loadingHandler(false);
  }, []);
  const [iAddworkList, setIAddworkList] = useState<IAddwork[]>([]);
  const [iAddworkFilter, setIAddworkFilter] = useState<string[]>();
  const [iAddworkFilterNow, setIAddworkFilterNow] = useState<string>('전체');
  useEffect(() => {
    if (payload) {
      let copy = payload.data.filter((a) => a.site === sellerNameEN);
      setIAddworkList(copy);
      let filt = copy
        .filter((a) => a.groupCode)
        .map((b) => {
          return b.groupCode;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setIAddworkFilter(filt);
      changeIAddworkFilter(iAddworkFilterNow);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  const changeIAddworkFilter = (group: string) => {
    if (payload) {
      if (group !== '전체') {
        let copy = payload.data.filter(
          (a) => a.site === sellerNameEN && a.groupCode === group,
        );
        setIAddworkList(copy);
      } else {
        let copy = payload.data.filter((a) => a.site === sellerNameEN);
        setIAddworkList(copy);
      }
      setIAddworkFilterNow(group);
    } else {
      console.log('payload yet');
    }
  };

  /** S: 제품 연결관리 */
  const ChangeLinked = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const { value } = e.target;
    if (firstInputs._id) {
      !iAddworkList[idx].productId.includes(firstInputs._id)
        ? UseAddworkAPI('add', value, firstInputs._id)
        : UseAddworkAPI('del', value, firstInputs._id);
    } else {
      alert('No firstInputs');
    }
  };
  /** E: 제품 연결관리 */

  /** S: 후가공 API */
  const UseAddworkAPI = async (
    kind: string,
    addworkID: string,
    productID: string,
  ) => {
    loadingHandler(true);
    const requestOptions: AxiosRequestConfig =
      kind === 'add'
        ? {
            url:
              SERVER_URL +
              '/addwork/push?addworkId=' +
              addworkID +
              '&productId=' +
              productID,
            method: 'PUT',
          }
        : {
            url:
              SERVER_URL +
              '/addwork/pull?addworkId=' +
              addworkID +
              '&productId=' +
              productID,
            method: 'PUT',
          };
    try {
      const response = await axios(requestOptions);
      response && loadingHandler(false);
      return;
    } catch (e) {
      loadingHandler(false);
      console.log(e);
    }
  };
  /** E: 후가공 API */
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeLPModal(false)}
      ></div>
      <div id="standardproduct-window" className="preview-window">
        <div
          className="closeBtn"
          onClick={() => {
            changeLPModal(false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>
        <div className="bg-f7f7f7 p-4">
          <div className="contents-wrap x_panel">
            <div className="x_title">
              <h4>
                <span>
                  <i>{siteName}</i>
                </span>{' '}
                - {firstInputs.productNameKO} 연결 후가공 변경
              </h4>
            </div>
            <div className="clearfix"></div>
            <div className="x_content">
              <div className="form-horizontal form-label-left">
                <div className="newoption-wrap form-group">
                  <div className="row">
                    <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                      기준제품 선택
                    </div>
                    <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                      <div className="groupFilter-wrap blue mb-2">
                        <div className="groupFilter-inner">
                          <div
                            className={`groupFilter-item ${
                              iAddworkFilterNow == '전체' ? 'active' : ''
                            }`}
                            onClick={() => changeIAddworkFilter('전체')}
                          >
                            전체
                          </div>
                          {iAddworkFilter?.map((filt) => {
                            return (
                              <div
                                key={filt}
                                className={`groupFilter-item ${
                                  iAddworkFilterNow == filt ? 'active' : ''
                                }`}
                                onClick={() => changeIAddworkFilter(filt)}
                              >
                                {filt}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="wrap">
                        {iAddworkList &&
                          iAddworkList.map((item, idx) => {
                            return (
                              <div className="newoption-radio" key={item._id}>
                                <input
                                  id={item._id}
                                  value={item._id}
                                  name="productId"
                                  type="checkbox"
                                  className="boxInputs"
                                  checked={
                                    firstInputs._id
                                      ? iAddworkList[idx].productId.includes(
                                          firstInputs._id,
                                        )
                                      : false
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => ChangeLinked(e, idx)}
                                />
                                <label htmlFor={item._id}>
                                  {item.workTypeKO}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
