import { Dispatch, SetStateAction } from 'react';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput } from '@interfaces/tableCost.interfaces';
import { MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
interface Props {
  siteName: string;
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  closeModal: () => void;
}
/** 모달STYLE */
const OVERLAY_STYLE: CSS.Properties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  background: 'rgba(0,0,0,.7)',
  zIndex: 100,
};
const CLOSE_STYLE: CSS.Properties = {
  position: 'fixed',
  width: '30px',
  height: '30px',
  textAlign: 'center',
  lineHeight: '20px',
  fontSize: '30px',
  top: '40px',
  right: '40px',
  padding: 0,
  zIndex: 100,
  border: 'none',
  background: 'none',
};

export default function BundleModal({
  siteName,
  firstInputs,
  setFirstInputs,
  closeModal,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    let copySelecters = [...firstInputs.selecters];
    copySelecters[index].selecterName = e.target.value;
    setFirstInputs({ ...firstInputs, selecters: copySelecters });
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const saveNow = async (action: string) => {
    loadingHandler(true);
    let address = firstInputs._id;
    await axios
      .put(SERVER_URL + '/product/' + address, firstInputs)
      .then(function (response) {
        if (action === 'save') {
          alert('저장완료');
        } else if (action === 'complete') {
          closeModal();
        }
        loadingHandler(false);
        alert(MESSAGE.SAVE);
      })
      .catch(function (error) {
        console.log('데이터 업로드에 실패 하였습니다.');
        loadingHandler(false);
      });
  };
  return (
    <>
      <div
        className="modalY-overlay"
        style={OVERLAY_STYLE}
        onClick={() => closeModal()}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-600">
        <div
          className="closeBtn"
          style={CLOSE_STYLE}
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            <div className="bg-f7f7f7 p-4">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{siteName}</i>
                    </span>{' '}
                    - {firstInputs.productNameKO} 묶음배송 설정
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>옵션명</th>
                              <th>주문옵션 코드값</th>
                            </tr>
                          </thead>
                          <tbody>
                            {firstInputs &&
                              firstInputs.selecters.map(
                                (newCategoryMap, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="col-3 col-sm-4  col-xs-12">
                                        <input
                                          type="text"
                                          className="form-control megaModalTitle"
                                          placeholder="옵션 타이틀입력해주세요"
                                          name="selecterName"
                                          value={
                                            newCategoryMap.selecterName || ''
                                          }
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>,
                                          ) => handleChange(e, index)}
                                          required
                                        />
                                      </td>
                                      <td className="col-9 col-sm-8 col-xs-12">
                                        <div className="option-input-group-wrap ub">
                                          <div className="input-group">
                                            <select
                                              className="form-control"
                                              name="types"
                                            >
                                              {firstInputs.selecters[index]
                                                .types === undefined ||
                                              firstInputs.selecters[index].types
                                                .length === 0 ||
                                              firstInputs.selecters[index]
                                                .types[0].code === ''
                                                ? '빈공간을 클릭하면 주문옵션 코드선택 창이 나옵니다.'
                                                : firstInputs.selecters[
                                                    index
                                                  ].types.map((b, i) => {
                                                    return (
                                                      <option key={b.code}>
                                                        {b.title}
                                                      </option>
                                                    );
                                                  })}
                                            </select>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  <div
                    className="price-start-btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      saveNow('complete');
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      수정완료
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
